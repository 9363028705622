import axios from "axios";
import { AuthHeader, Tenant, url } from "./_exports";
import { abortSignal } from "../../utils/abortSignal";

export const getShares = () => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/deltashare/shares/`, {
				...AuthHeader,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getSchemas = (share: string) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/deltashare/schemas`, {
				params: {
					share,
				},
				...AuthHeader,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getTables = (data: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/deltashare/tables`, {
				params: {
					...data,
				},
				...AuthHeader,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getAllTables = (share: string) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/deltashare/alltables`, {
				params: {
					share,
				},
				...AuthHeader,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getMetaData = (share: string, schema: string, table: string) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/deltashare/metadata/`, {
				params: {
					share,
					schema,
					table,
				},
				...AuthHeader,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getData = (table: string) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${table}/`, {
				...AuthHeader,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const getData2 = (
	share: string,
	schema: string,
	table: string,
	options: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/deltashare/query2?share=${share}&schema=${schema}&table=${table}`,
				options,
				{ ...AuthHeader }
			)
			.then((response) => {
				resolve(response.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};

export const duckQuery = (
	options: any,
	allowString: boolean = false,
	queryString?: string,
	restricted?: Array<any> // restricted datasets
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/deltashare/duckq`,
				{
					allowString,
					queryString,
					options,
					restricted,
				},
				{
					...AuthHeader,
					signal: abortSignal(3000),
				}
			)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error?.response?.data || "There is an error running your query");
			});
	});
};
