import React, { useRef, useEffect, useState, useMemo, useContext } from "react";
import { Engine, Scene } from "react-babylonjs";
import * as BABYLON from "@babylonjs/core";
import * as BABYLONMAT from "@babylonjs/materials";
import { url } from "../services/api-server/_exports";
import NoAccess from "./NoAccess";
import { getEngineeringAsset } from "../services/api-server/digitaltwin";
import {
	Drawer,
	Modal,
	Progress,
	Slider,
	Spin,
	Menu,
	Input,
	Descriptions,
	Tree,
	Space,
	Typography,
	Select,
	Switch,
	Divider,
	Button,
	Popover,
	Checkbox,
	Empty,
	DescriptionsProps,
	Tabs,
} from "antd";
import Portal_Icon_Logo from "../assets/img/logo192.png";
import Test from "./../assets/img/newnewnew3.png";
// import Video from "./../assets/video/Download.mp4";
// import Audio from "./../assets/video/Monday.mp3";
import { getMaximoData } from "../services/api-server/maximo";
import { GetAntIcon } from "../utils/ant_icons";
import axios from "axios";
import { socket } from "../utils/socket";
import { Inspector } from "@babylonjs/inspector";
import { useLocation, useOutletContext } from "react-router-dom";
import { getVesselTag } from "../services/api-server/kognifai";
import dayjs from "dayjs";
import {
	KeyboardSectionSVG,
	MouseSectionSVG,
	searchVesselSVG,
	accessVesselSVG,
	navigateSVG,
	mouseSVG,
	keyboardSVG,
} from "../components/Svg";
import MenuDivider from "antd/es/menu/MenuDivider";
import { duckQuery, getData2 } from "../services/api-server/deltashare";
import "../assets/css/digitalTwin.css";

const { Text, Title } = Typography;
const { Search } = Input;
const { TreeNode } = Tree;

const defaultPosition = new BABYLON.Vector3(106.97, 60.05, -108.46);
let cloned = defaultPosition.clone();
const defaultTarget = new BABYLON.Vector3(98.92, 46.94, 282.67);

//V1.5 PointerMove Timeout
let pointerMoveTimerID: any = null;
let popoverTimerID: any = null;
let defaultInterval: any = null;

let defaultInvisibleAsset: any = true;

// All current animation tags
const kognifai_data_series = [
	"StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI-101-A00",
	"StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI2-101-A00",
];

// Animation tags to mesh match
const kognifai_data_series_animation: any = {
	"StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI-101-A00": [
		{
			objectName: "P.313001",
			objectType: "pickable",
			minY: 51,
			denominator: 1,
		},
		{
			objectName: "P.312026",
			objectType: "pickable",
			minY: 82,
			denominator: 2,
		},
		{
			objectName: "A.312025",
			objectType: "animation",
			minY: 74.6,
			denominator: 2,
		},
	],
	"StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI2-101-A00": [
		{
			objectName: "P.313002",
			objectType: "pickable",
			minY: 51,
			denominator: 1,
		},
		{
			objectName: "P.312031",
			objectType: "pickable",
			minY: 82,
			denominator: 2,
		},
		{
			objectName: "A.312030",
			objectType: "animation",
			minY: 74.6,
			denominator: 2,
		},
	],
};

const crewModelDeltaQueryOptions = {
	from: {
		tables: [
			{
				name: "crew_model",
			},
		],
	},
	where: {
		columns: [
			{
				name: "vessel_id",
				table: { name: "crew_model" },
				op: "=",
				value: 900,
			},
		],
	},
};

//Main Code
const DigitalTwin = (props: any) => {
	const originalClipPlanes: any = {
		clipPlane: new BABYLON.Plane(1, 0, 0, -10000),
		clipPlane2: new BABYLON.Plane(-1, 0, 0, -10000),
		clipPlane3: new BABYLON.Plane(0, 1, 0, -100000),
		clipPlane4: new BABYLON.Plane(0, -1, 0, -10000),
		clipPlane5: new BABYLON.Plane(0, 0, 1, -10000),
		clipPlane6: new BABYLON.Plane(0, 0, -1, -100000),
	};

	const clippingPlanesRef = useRef<any>({
		...originalClipPlanes,
	});

	const mqttRef = useRef<any>([]);
	const searchInputRef = useRef<any>(null);
	const assetTreeRef = useRef<any>(null);
	const pobTreeRef = useRef<any>(null);

	//Babylon Must Have Ref
	const sceneRef = useRef<any>(null);
	const engineRef = useRef<any>(null);
	const canvasRef = useRef<any>(null);

	//Camera Ref
	const cameraRef = useRef<any>(null);
	const cameraArcRef = useRef<any>(null);
	const cameraComRef = useRef<any>(null);
	const activeCameraRef = useRef<any>(null);
	const previousPosition = useRef<any>({});

	//Babylon Common Ref
	const mouseHoldRef = useRef<any>(null);
	const movementRef = useRef<any>([]);
	const crosshairRef = useRef<any>(null);
	const boxRef = useRef<any>(null);
	const smallBoxRef = useRef<any>(null);
	const skyRef = useRef<any>(null);
	const oceanRef = useRef<any>(null);

	//Focus Mode Ref
	const isFocusedRef = useRef<any>(false);
	const rightDrawerKeyRef = useRef<any>("");

	//Level Refs
	const level1Ref = useRef<any>(null);
	const level2Ref = useRef<any>(null);
	const level3Ref = useRef<any>(null);

	//Meshes
	const downloadingRef = useRef<any>(false);
	const highPolyMeshes = useRef<any>(null);
	const AllMeshes = useRef<any>(null);
	const pickables = useRef<any>(null);
	const animateMesh = useRef<any>(null);
	const engineerMesh = useRef<any>([]);
	const meshRefs = useRef<any>([]);
	const highlightedAssetRef = useRef<any>([]);
	const closeMeshRef = useRef<any>([]);
	const Pointerlock = useRef<any>(false);

	const hullRef = useRef<any>([]);
	const pointerClickRef: any = useRef<any>(null);

	//Animations
	const animationGroupRef = useRef<any>([]);

	const intervalRef = useRef<any>([]);

	const isInspectorOpenRef = useRef<any>(false);

	const [options, setOptions] = useState<any>({
		enablePickable: false,
		enableHighPoly: true,
		enableOcean: true,
	});

	const [scene, setScene] = useState<any>(null);
	const [canvas, setCanvas] = useState<any>(null);

	const [graphicalEngine, setGraphicEngine] = useState<string>(
		localStorage.getItem("graphicsEngine") || "WebGL"
	);

	//Babylon States
	const [FPS, setFPS] = useState<any>(0);
	const [cameraPosition, setCameraPosition] = useState<any>(null);
	const [cameraTarget, setCameraTarget] = useState<any>(null);
	const [cameraSpeed, setCameraSpeed] = useState<any>(2.55);
	const [cameraFov, setCameraFov] = useState<any>(1.5);
	const [cameraMode, setCameraMode] = useState<any>("1");

	const [highlightLayer, setHighlightLayer] = useState<any>(null);

	//Loading, Modal, Drawers
	const [isLoaded, setLoaded] = useState<any>(false);
	const [isAssetLoaded, setAssetLoaded] = useState<any>(true);
	//Maximo
	const [isMaximoView, setMaximoView] = useState<boolean>(false);
	const [isMaximoLoading, setMaximoLoading] = useState<any>(false);
	const [maximoData, setMaximoData] = useState<any>({});
	const [currentMaximoKey, setCurrentMaximoKey] = useState<any>("");
	const [leftDrawer, setLeftDrawer] = useState<any>(false);
	const [rightDrawer, setRightDrawer] = useState<any>(false);
	const [rightDrawerData, setRightDrawerData] = useState<any>({});
	const [rightDrawerKey, setRightDrawerKey] = useState<string>("");
	const [rightDrawerTabKey, setRightDrawerTabKey] =
		useState<string>("overview");

	const [menuKey, setMenuKey] = useState<any>("");
	const menuKeyRef = useRef<string>("");
	const [side_modal, setSideModal] = useState<any>(false);
	const [bottom_modal, setBottomModal] = useState<any>(false);
	const [tutorialModal, setTutorialModal] = useState<any>(false);
	const [tutorial, setTutorial] = useState<any>(false);
	const [FSModal, setFSModal] = useState<any>(false);
	const [FSMessage, setFSMessage] = useState<any>(false);

	const [mqttList, setMqttList] = useState<any>([]);

	const location = useLocation().pathname;
	const [acceptedroles, setacceptedroles] = useState<any>(true);
	const [ModelLoadPercent, setModelLoaded] = useState<any>(0);
	const [ModelLoadDesc, setModelLoadDesc] = useState<any>("Initialize engine");
	const [noShow, setNoShow] = useState<any>(false);

	const [seaState, setSeaState] = useState<any>({});

	const [currentCamera, setCurrentCamera] = useState<any>("1");

	const [assetHierarchy, setAssetHierarchy] = useState<any>(null);
	const [flatAssetHierarchy, setFlatAssetHierarchy] = useState<any>(null);

	const [pobHierarchy, setPobHierarchy] = useState<any>(null);
	const [pobData, setPobData] = useState<any>(null);
	const [selectedKeys, setSelectedKeys] = useState<any>([]);
	const [selectValue, setSelectValue] = useState<any>(null);
	const [searchResults, setSearchResults] = useState<Array<any>>([]);
	const [searchValue, setSearchValue] = useState<any>("");
	const [isSearch, setIsSearch] = useState(false);

	const [tempAssetKey, setTempAssetKey] = useState<any>(null);
	const [availableAssets, setAvailableAssets] = useState<any>(null);
	const [assetTreeSearchValue, setAssetTreeSearchValue] = useState("");
	const [expandedKeys, setExpandedKeys] = useState<any>([]);

	const popoverPlacementRef = useRef<any>("right");
	const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
	const [popoverContent, setPopoverContent] = useState<any>("");

	const getKognifaiData = (
		data_series: Array<any>,
		interval: string,
		rig: string
	) => {
		const promises = data_series.map((element: any) => {
			const tag: any = {};
			const start_date = dayjs().subtract(5000, "milliseconds").toISOString();
			const end_date = dayjs().toISOString();

			return getVesselTag(rig, element, start_date, end_date, "5m")
				.then((response: any) => {
					if (response.data.error) {
						console.error(response.data.error);
					} else {
						let data = response.data[0];
						data["series"] = element;
						return data;
					}
				})
				.catch((error) => {
					console.error(error);
				});
		});
		return Promise.all(promises);
	};

	// Generates a data tree hierarchy in antd format depending on the the type of flat data used
	const generateTreeData = (
		type: String,
		hierarchy: Array<any>,
		availableAssets: Array<any> | null = null
	) => {
		//A method to counter the number of leaf nodes for the parent
		const countLeafNodes: any = (node: any) => {
			if (!node || node.length <= 0) {
				return 1;
			} else {
				let leafCount = 0;
				node.forEach((leaf: any) => {
					leafCount += countLeafNodes(leaf.children);
				});
				return leafCount;
			}
		};

		//Traverse through the data and create a tree
		const traverse = (
			element: any,
			level: number = 1,
			parentKey: string = ""
		) => {
			const { title = "", key = "", children = [] } = element;
			const treePos = level === 1 ? key : `${parentKey}-${key}`;

			let data: any = {
				key,
				title: "",
			};
			switch (type) {
				case "Asset":
					const titleArray: Array<string> = title
						.trim()
						.split(/[\s+-]/)
						.filter((v: any) => v);
					// titleArray.splice(0, 1);
					const cleanedTitle = titleArray.join(" ");
					data.cleanedTitle = cleanedTitle;
					data.fullName = `${cleanedTitle} (${key})`;
					data.assetExist = findAsset(element, availableAssets, level);
					data.level = level;
					data.treePos = treePos;
					data.className = `digitaltwin-tree-level-${level}`;
					data.style = { marginLeft: `${24 * Number(level > 1)}px` };
					break;

				case "Crew":
					data = {
						...element,
					};
					data.cleanedTitle = title;
					data.treePos = treePos;
					data.level = level;
					data.occupation = title;
					data.className = `digitaltwin-tree-level-${level}`;
					data.style = { marginLeft: `${24 * Number(level > 1)}px` };

					break;
			}

			if (children && children.length !== 0) {
				data.children = children.map((child: any) =>
					traverse(child, level + 1, treePos)
				);
				data.childrenCount = countLeafNodes(children);
			}
			return data;
		};

		const treeData = hierarchy.map((element) => {
			return traverse(element);
		});

		return treeData;
	};

	const assetTreedata: any = useMemo(() => {
		if (assetHierarchy && availableAssets) {
			let t = generateTreeData("Asset", assetHierarchy, availableAssets);
			return t;
		}
	}, [assetHierarchy, pickables.current, availableAssets]);

	const pobTreeData: any = useMemo(() => {
		console.log(pobHierarchy);
		if (pobHierarchy) {
			let res = generateTreeData("Crew", pobHierarchy, null);
			return res;
		}
	}, [pobHierarchy]);

	const handleSearch = (value: string, isSpecific: boolean = false) => {
		let expandedKeys: Array<any> = [];
		const filterTreeData = (
			nodes: Array<any>,
			key: any,
			searchText: string
		) => {
			let filteredNodes: Array<any> = [];
			nodes.forEach((node) => {
				if (key == "Asset") {
					if (isSpecific) {
						if (node.key.toLowerCase() == searchText) {
							filteredNodes.push(node);
						} else if (node.children) {
							const filteredChildren = filterTreeData(
								node.children,
								key,
								searchText
							);
							if (filteredChildren.length > 0) {
								filteredNodes = filteredChildren;
							}
						}
					} else {
						if (node.fullName.toLowerCase().includes(searchText)) {
							filteredNodes.push(node);
							expandedKeys.push(node.key);
						} else if (node.children) {
							const filteredChildren = filterTreeData(
								node.children,
								key,
								searchText
							);
							if (filteredChildren.length > 0) {
								filteredNodes.push({ ...node, children: filteredChildren });
								expandedKeys.push(node.key);
							}
						}
					}
				} else if (key == "Crew") {
					if (
						node.full_name?.toLowerCase().includes(searchText) ||
						node.occupation?.toLowerCase().includes(searchText)
					) {
						filteredNodes.push(node);
						expandedKeys.push(node.key);
					} else if (node.children) {
						const filteredChildren = filterTreeData(
							node.children,
							key,
							searchText
						);
						if (filteredChildren.length > 0) {
							filteredNodes.push({ ...node, children: filteredChildren });
							expandedKeys.push(node.key);
						}
					}
				}
			});
			return filteredNodes;
		};
		const splitSearchValue = value.split("_");
		const searchKey: any = splitSearchValue.pop();
		const searchValue = splitSearchValue.join("_");

		const cleanedValue = searchValue.trim().toLowerCase();
		if (cleanedValue === "") {
			return;
		}

		let searchData: any = null;
		switch (searchKey) {
			case "Asset":
				searchData = assetTreedata;
				break;
			case "Crew":
				searchData = pobTreeData;
				break;
		}

		console.log(searchData);
		const filteredTreeData = filterTreeData(
			searchData,
			searchKey,
			cleanedValue
		);
		if (isSpecific) {
			return filteredTreeData;
		} else {
			setIsSearch(true);
			if (expandedKeys.length < 20) {
				setExpandedKeys(expandedKeys);
			}
			setSearchResults(filteredTreeData);
		}
	};

	const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
		const value = ev.target.value;
		setSearchValue(ev.target.value);
		if (value === "" && isSearch) {
			setSearchResults([]);
			setIsSearch(false);
			setExpandedKeys([]);
		}
	};

	const handleAssetTreeSelect = (keys: any, node: any) => {
		canvasRef.current.focus();
		if (keys.length > 0) {
			console.log(keys);
			let key = keys[0];
			setSelectedKeys(keys);
			setSelectValue(null);
			setRightDrawerKey("Asset");

			if (node.assetExist) {
				if (key) {
					const check = isNaN(parseInt(key.charAt(key.length - 1)));
					if (check) {
						key = key.slice(0, -1);
					}
					const treePos = node.treePos.split("-");
					const level: any = node.level;

					if (level == 1) {
						if (engineerMesh.current.length > 0) {
							engineerMesh.current.map((element: any) => {
								highlightLayer.removeExcludedMesh(element);
								highlightLayer.removeMesh(element);
								element.dispose();
							});
							engineerMesh.current = [];
							setMaximoData({});

							scene.activeCamera.detachControl();
							scene.activeCamera = activeCameraRef.current;
							scene.activeCamera.attachControl(canvas, false);

							console.log(previousPosition);

							scene.activeCamera.position = previousPosition.current.position;
							scene.activeCamera.target = previousPosition.current.target;
							level3Ref.current = null;
							level2Ref.current = null;
						}
						AllMeshes.current.map((element: any) => {
							element.setEnabled(false);
						});

						setMaximoData({});
						isFocusedRef.current = true;
						oceanRef.current.isVisible = false;
						skyRef.current.isVisible = false;

						let meshes = AllMeshes.current.filter((element: any) => {
							return element.name.includes(`(${node.key})`);
						});
						if (meshes.length > 0) {
							meshes.forEach((element: any) => {
								element.setEnabled(true);
							});
						}
						setRightDrawer(true);
						rightDrawerKeyRef.current = "Asset";
						setMaximoLoading(true);
						setMaximoView(true);
						getAssetData(key);
					} else if (level > 1) {
						let isEngineer: boolean = false;
						let meshes = pickables.current?.filter((element: any) => {
							return element.name.includes(key);
						});
						let parentAssetKey: any = null;

						if (engineerMesh.current.length > 0 && meshes.length == 0) {
							meshes = engineerMesh.current.filter((element: any) => {
								return element.name.includes(key);
							});
							if (meshes.length > 0) isEngineer = true;
						}
						if (meshes.length == 0 && isEngineer === false) {
							parentAssetKey = treePos[treePos.length - 2];
							meshes = pickables.current?.filter((element: any) => {
								return element.name.includes(parentAssetKey);
							});
						}

						if (meshes.length > 0) {
							if (!isEngineer) {
								// console.log(level2Ref.current);
								// console.log(meshes[0]);
								if (meshes[0].name === level2Ref.current) {
									// console.log("This is true)");
									setRightDrawer(true);
									rightDrawerKeyRef.current = "Asset";
									setMaximoLoading(true);
									setMaximoView(true);
									getAssetData(key);

									if (level3Ref.current) {
										// level3Ref.current.renderOutline = false;
										highlightLayer.addExcludedMesh(level3Ref.current);
										level3Ref.current = null;
									}
									highlightedAssetRef.current = [];

									engineerMesh.current.map((element: any) => {
										element.visibility = 1;
									});
								} else {
									engineerMesh.current.map((element: any) => {
										highlightLayer.removeExcludedMesh(element);
										highlightLayer.removeMesh(element);
										element.dispose();
									});
									engineerMesh.current = [];
									setMaximoData({});
									level3Ref.current = null;
									level2Ref.current = null;
									const regex = /\([^)]*\)/;

									// Replace the matched text with an empty string
									const resultString = meshes[0].name.replace(regex, "");

									if (parentAssetKey) {
										LoadComponent(scene, resultString, key);
									} else {
										LoadComponent(scene, resultString);
										setRightDrawer(true);
										rightDrawerKeyRef.current = "Asset";
										setMaximoLoading(true);
										setMaximoView(true);
										getAssetData(key);
									}
								}
							} else {
								if (level3Ref.current != meshes[0]) {
									isFocusedRef.current = true;
									setRightDrawer(true);
									rightDrawerKeyRef.current = "Asset";
									setMaximoLoading(true);
									setMaximoView(true);
									getAssetData(key);
									engineerMesh.current.forEach((mesh: any) => {
										mesh.visibility = 0.03;
									});
									if (level3Ref.current) {
										// level3Ref.current.renderOutline = false;
										highlightLayer.addExcludedMesh(level3Ref.current);
									}

									highlightedAssetRef.current = [];
									highlightLayer.removeMesh(meshes[0]);
									highlightLayer.addMesh(
										meshes[0],
										BABYLON.Color3.FromHexString("#7DF9FF")
									);
									level3Ref.current = meshes[0];
									highlightLayer.removeExcludedMesh(meshes[0]);
									meshes[0].visibility = 1;
								}
							}
						}
					}
				} else {
					scene.activeCamera.detachControl();
					scene.activeCamera = cameraRef.current;
					//console.log(cameraRef.current);
					scene.activeCamera.attachControl(canvas, false);
					AllMeshes.current.map((element: any) => {
						element.setEnabled(true);
					});
					if (engineerMesh.current.length > 0) {
						engineerMesh.current.map((element: any) => {
							element.dispose();
						});
						engineerMesh.current = [];
					}

					setMaximoData({});
					setMaximoView(false);
					isFocusedRef.current = false;
					setRightDrawer(false);

					setSelectedKeys([]);
					setSelectValue(null);
					oceanRef.current.isVisible = true;
					skyRef.current.isVisible = true;
					level2Ref.current = null;
					level3Ref.current = null;
					level1Ref.current = null;
				}
			} else {
				setMaximoData({});
				setRightDrawer(false);
				rightDrawerKeyRef.current = "";
				const message = `${node.fullName}`;
				setFSMessage(message);
				setFSModal(true);
				// console.log(key);
				setCurrentMaximoKey(key);
				if (level2Ref.current) {
				}
				if (level3Ref.current) {
					// level3Ref.current.renderOutline = false;
					highlightLayer.addExcludedMesh(level3Ref.current);
					highlightedAssetRef.current = [];
					highlightLayer.removeMesh(level3Ref.current);
					engineerMesh.current.forEach((mesh: any) => {
						mesh.visibility = 1;
					});
				}
				level2Ref.current = null;
				level3Ref.current = null;
			}
		} else {
			setSelectedKeys([]);
			setRightDrawer(false);
			setRightDrawerKey("");
		}
	};

	const DrawerMemo: any = useMemo(() => {
		const setTitle = () => {
			switch (menuKey) {
				case "Asset":
					return (
						<Select
							onChange={(value: any) => {}}
							defaultValue="1"
							style={{ width: 120 }}
							bordered={false}
							options={[
								{ value: "1", label: "Assets" },
								{ value: "2", label: "Available Assets" },
								{ value: "3", label: "Unavailable Assets" },
							]}
						/>
					);
				case "Crew":
					return (
						<Select
							defaultValue="1"
							style={{ width: 120 }}
							bordered={false}
							options={[
								{ value: "1", label: "Crew" },
								{ value: "2", label: "Available Crew" },
								{ value: "3", label: "Unavailable Crew" },
							]}
						/>
					);
				case "Help":
					return "Navigate the 3D Model";
				default:
					return menuKey;
			}
		};
		const setContent = () => {
			//console.log("Did this happen?");
			clearInterval(defaultInterval);
			switch (menuKey) {
				case "Help":
					defaultInterval = setInterval(() => {
						setFPS(scene.getEngine().getFps().toFixed());
						if (cameraRef.current) {
							setCameraFov(cameraRef.current.fov);
							setCameraSpeed(cameraRef.current.speed);
							setCameraPosition(
								new BABYLON.Vector3(
									Math.round(cameraRef.current.position._x * 100) / 100,
									Math.round(cameraRef.current.position._y * 100) / 100,
									Math.round(cameraRef.current.position._z * 100) / 100
								)
							);
							setCameraTarget(
								new BABYLON.Vector3(
									Math.round(cameraRef.current.target._x * 100) / 100,
									Math.round(cameraRef.current.target._y * 100) / 100,
									Math.round(cameraRef.current.target._z * 100) / 100
								)
							);
						}
					}, 1000);
					return (
						<div style={{ padding: "12px" }}>
							<MouseSectionSVG />
							<Divider />
							<KeyboardSectionSVG />
							<Divider />
							<Descriptions
								title={
									<Text
										style={{
											alignSelf: "start",
											fontSize: 20,
											fontWeight: "normal",
										}}
									>
										Statistics
									</Text>
								}
								contentStyle={{ textAlign: "end", display: "table-cell" }}
							>
								<Descriptions.Item span={5} label="FPS">
									{FPS}
								</Descriptions.Item>
								<Descriptions.Item span={5} label="Camera Speed">
									{cameraSpeed}
								</Descriptions.Item>
								<Descriptions.Item span={5} label="Camera FOV">
									{cameraFov}
								</Descriptions.Item>
								<Descriptions.Item span={5} label="Camera Position">
									{cameraPosition?._x}, {cameraPosition?._y},{" "}
									{cameraPosition?._z}
								</Descriptions.Item>
								<Descriptions.Item span={5} label="Camera Target">
									{cameraTarget?._x}, {cameraTarget?._y}, {cameraTarget?._z}
								</Descriptions.Item>
							</Descriptions>
						</div>
					);

				case "Asset":
					return (
						<Space
							direction="vertical"
							style={{ height: "100%", width: "100%", overflow: "hidden" }}
							className="babylon-asset-body"
							styles={{
								item: { height: "fit-content", maxHeight: "calc(100% - 32px)" },
							}}
							size={0}
						>
							<Search
								placeholder="Search for asset key or name..."
								key={"asset-searchbar"}
								onSearch={(value: any) => handleSearch(value + "_Asset")}
								onChange={handleSearchChange}
								allowClear
							/>

							{!isSearch || (isSearch && searchResults.length !== 0) ? (
								<Tree
									treeData={
										isSearch && searchResults.length > 0
											? searchResults
											: assetTreedata
									}
									titleRender={(node: any) => {
										const level: any = node.level;
										return getFormattedTitle(
											"Asset",
											level,
											node,
											node.assetExist
										);
									}}
									className="babylon-asset-tree"
									virtual={true}
									motion={false}
									ref={assetTreeRef}
									selectedKeys={selectedKeys}
									expandedKeys={expandedKeys}
									onMouseEnter={(info: any) => {
										console.log(info.node.key);
										console.log(info.node.assetExist);
										let key = info.node.key;
										const check = isNaN(parseInt(key.charAt(key.length - 1)));
										if (check) {
											key = key.slice(0, -1);
										}
										const level = info.node.pos.split("-").length - 1;
										if (level == 1 && info.node.assetExist) {
											let meshes = AllMeshes.current.filter((element: any) => {
												return element.name.includes(`(${info.node.key})`);
											});

											console.log(AllMeshes);
											console.log(meshes);

											if (meshes.length > 0) {
												if (highlightedAssetRef?.current.length > 0) {
													removeHighlightedAsset();
												}
												meshes.forEach((element: any) => {
													highlightLayer.removeExcludedMesh(element);
													highlightLayer.addMesh(
														element,
														BABYLON.Color3.FromHexString("#7DF9FF")
													);
												});

												// meshes[0].renderOutline = true;
												highlightedAssetRef.current = meshes;
											}
										}
										if (level == 2 || level == 3) {
											let isEngineer = false;
											let meshes = pickables.current?.filter((element: any) => {
												return element.name.includes(key);
											});

											if (meshes.length === 0) {
												meshes = engineerMesh.current.filter((element: any) => {
													return element.name.includes(key);
												});
												if (meshes.length > 0) isEngineer = true;
											}

											if (meshes.length > 0) {
												if (
													!highlightedAssetRef.current[0]?.name.includes(
														meshes[0]
													) &&
													meshes[0] != level3Ref.current
												) {
													if (highlightedAssetRef?.current.length > 0) {
														removeHighlightedAsset();
													}
													meshes.forEach((element: any) => {
														highlightLayer.removeExcludedMesh(element);
														highlightLayer.addMesh(
															element,
															BABYLON.Color3.FromHexString("#7DF9FF")
														);
													});

													// meshes[0].renderOutline = true;
													highlightedAssetRef.current = meshes;
												}
											}
										}
									}}
									onMouseLeave={() => {
										removeHighlightedAsset();
									}}
									onSelect={(keys: any, info: any) => {
										handleAssetTreeSelect(keys, info.node);
									}}
									onExpand={(keys: any, info: any) => {
										canvasRef.current.focus();
										if (info.expanded === false) {
											setExpandedKeys(
												keys.filter(
													(item: any) => !item.includes(info.node.key)
												)
											);
										} else {
											setExpandedKeys(keys);
										}
									}}
									rootClassName="digital-twin-asset-tree"
									style={{
										color: "rgba(255, 255, 255, 0.85)",
										marginTop: "8px",
									}}
									rootStyle={{ background: "none" }}
								/>
							) : (
								<Empty />
							)}
						</Space>
					);
				case "Crew":
					return (
						<Space
							direction="vertical"
							style={{ height: "100%", width: "100%", overflow: "hidden" }}
							className="babylon-asset-body"
							styles={{
								item: { height: "fit-content", maxHeight: "calc(100% - 32px)" },
							}}
							size={0}
						>
							<Search
								placeholder="Search for name or role..."
								key={"crew-searchBar"}
								onSearch={(value: any) => handleSearch(value + "_Crew")}
								onChange={handleSearchChange}
								allowClear
							/>

							{pobTreeData &&
							(!isSearch || (isSearch && searchResults.length !== 0)) ? (
								<Tree
									treeData={
										isSearch && searchResults.length > 0
											? searchResults
											: pobTreeData
									}
									titleRender={(node: any) => {
										const level: any = node.level;
										return getFormattedTitle("Crew", level, node, true, false);
									}}
									className="babylon-asset-tree"
									virtual={true}
									motion={false}
									ref={pobTreeRef}
									selectedKeys={selectedKeys}
									expandedKeys={expandedKeys}
									onExpand={(keys: any) => {
										setExpandedKeys(keys);
										canvasRef.current.focus();
									}}
									onSelect={(keys: any, info: any) => {
										canvasRef.current.focus();
										setRightDrawer(info.selected);
										rightDrawerKeyRef.current = "Crew";
										setSelectedKeys(keys);
										setRightDrawerKey("Crew");
										setRightDrawerData(info.node);
										setMaximoData({});
										setMaximoView(false);
									}}
									rootClassName="digital-twin-asset-tree"
									style={{
										color: "rgba(255, 255, 255, 0.85)",
										marginTop: "8px",
									}}
									rootStyle={{ background: "none" }}
								/>
							) : (
								<Empty style={{ marginTop: "20px" }} />
							)}
						</Space>
					);

				case "MQTT":
					return (
						<div>
							<Descriptions layout="vertical" bordered>
								{mqttList.map((element: any) => {
									return (
										<Descriptions.Item span={40} label={element.Name}>
											{element.Value}
										</Descriptions.Item>
									);
								})}
							</Descriptions>
						</div>
					);
				case "Settings":
					return (
						<>
							<div>
								Hide High Poly Meshes:{" "}
								<Switch
									checked={options.enableHighPoly}
									onChange={(boolean: any) => {
										setOptions({ ...options, enableHighPoly: boolean });
										highPolyMeshes.current.forEach((element: any) => {
											element.setEnabled(!boolean);
										});
									}}
								/>
							</div>
							<div>
								Enable Level of Detail:
								<Switch
									checked={options.enablePickable}
									onChange={(boolean: any) => {
										setOptions({ ...options, enablePickable: boolean });
										pickables.current?.forEach((element: any) => {
											if (boolean) {
												element.addLODLevel(100, null);
											} else {
												element.removeLODLevel(null);
											}
										});
									}}
								/>
							</div>
							<div>
								Enable Sea:
								<Switch
									checked={options.enableOcean}
									onChange={(boolean: any) => {
										oceanRef.current.isVisible = boolean;
										setOptions({ ...options, enableOcean: boolean });
									}}
								/>
							</div>
							<Space
								direction="vertical"
								style={{ width: "100%", overflow: "hidden" }}
							>
								{Object.keys(clippingPlanesRef.current).map((element: any) => {
									return (
										<Slider
											min={-1000}
											max={1000}
											onChange={(value: any) => {
												sceneRef.current[element].d = value;
											}}
											defaultValue={-100}
										/>
									);
								})}
							</Space>
						</>
					);
				default:
					return <></>;
			}
		};
		return (
			<Drawer
				headerStyle={{ padding: "8px 16px", fontSize: "14px" }}
				bodyStyle={{ padding: "0px 0px 12px 0px" }}
				open={leftDrawer}
				closeIcon={GetAntIcon("leftarrow")}
				width={350}
				placement="left"
				className={`digitaltwin-drawer-wrapper`}
				onClose={() => {
					canvasRef.current.focus();
					if (
						rightDrawerKeyRef.current === menuKey ||
						["Help", "Settings"].includes(menuKey)
					) {
					} else {
						setRightDrawer(false);
						setSelectedKeys([]);
						if (engineerMesh.current.length > 0) {
							engineerMesh.current.map((element: any) => {
								highlightLayer.removeExcludedMesh(element);
								highlightLayer.removeMesh(element);
								element.dispose();
							});
							engineerMesh.current = [];
							setMaximoData({});
							AllMeshes.current.map((element: any) => {
								element.setEnabled(true);
							});
							scene.activeCamera.detachControl();

							scene.activeCamera = activeCameraRef.current;
							scene.activeCamera.attachControl(canvas, false);

							scene.activeCamera.position = previousPosition.current.position;
							scene.activeCamera.target = previousPosition.current.target;
							level3Ref.current = null;
							level2Ref.current = null;

							setMaximoData({});
							isFocusedRef.current = false;
							oceanRef.current.isVisible = true;
							skyRef.current.isVisible = true;
						}
					}

					setLeftDrawer(false);
					Inspector.Hide();
					isInspectorOpenRef.current = false;
					setTimeout(() => {
						setIsSearch(false);
						setSearchResults([]);
						setSearchValue("");
						setMenuKey(null);
						menuKeyRef.current = "";
						setExpandedKeys([]);
					}, 100);
				}}
				getContainer={false}
				mask={false}
				maskClosable={false}
				destroyOnClose={false}
				closable={true}
				title={setTitle()}
				style={{ position: "absolute" }}
				afterOpenChange={() => canvasRef.current.focus()}
			>
				<div style={{ color: "white", height: "100%" }}>{setContent()}</div>
			</Drawer>
		);
	}, [
		leftDrawer,
		isSearch,
		searchResults,
		expandedKeys,
		cameraSpeed,
		cameraFov,
		cameraTarget,
		selectedKeys,
		selectValue,
		mqttList,
	]);

	const showPopover = (asset: any, event: any) => {
		clearTimeout(popoverTimerID);

		if (event === true) {
			popoverTimerID = setTimeout(() => {
				let assetnum: any = null;
				if (level2Ref.current || level3Ref.current) {
					assetnum = asset.replace("P.", "").trim().split(/[-\s]/)[0];
				} else {
					assetnum = asset.replace("P.", "").trim().split(/[-\s]/)[1];
				}

				let message = flatAssetHierarchy.find((element: any) => {
					// console.log(element);
					// console.log(assetnum);
					return element.includes(assetnum);
				});
				if (message) {
					setPopoverContent(message);
					setPopoverVisible(event);
				} else {
					setPopoverContent(assetnum);
					setPopoverVisible(false);
				}
			}, 1);
		} else {
			setPopoverVisible(false);
		}
	};

	const selectData: any = useMemo(() => {
		let flattenArray: any = [];

		const renderTreeOptions = (assetHierarchy: any) => {
			assetHierarchy.forEach((node: any) => {
				const { key, title, children } = node;

				flattenArray.push({
					label: title + ` (${key})`,
					value: key,
				});

				if (children && children.length > 0) {
					renderTreeOptions(children);
				}
			});
		};

		if (assetHierarchy) {
			renderTreeOptions(assetHierarchy);
			return flattenArray;
		}
	}, [assetHierarchy]);

	var shiftKeyDown: boolean = false;
	var spaceKeyDown: boolean = false;

	const startUp = () => {
		if (localStorage.getItem("skipTutorial") != "true") {
			setTutorial(1);
		}
	};

	//Engine Initialization
	useEffect(() => {
		if (acceptedroles && graphicalEngine === "WebGPU") {
			const canvas = canvasRef.current;
			const engine = new BABYLON.WebGPUEngine(canvas, {
				setMaximumLimits: true,
				enableAllFeatures: true,
			});

			engine
				.initAsync()
				.then(() => {
					var createScene = function () {
						var scene = new BABYLON.Scene(engine);

						const camera = new BABYLON.UniversalCamera(
							"UniCamera",
							defaultPosition.clone(),
							scene
						);
						camera.target = defaultTarget;
						camera.speed = cameraSpeed;
						camera.inertia = 0;
						camera.angularSensibility = 1000;
						camera.minZ = 0.3;
						camera.fov = cameraFov;
						camera.attachControl(canvas, true);

						cameraRef.current = camera;

						const ArcCamera = new BABYLON.ArcRotateCamera(
							"RotateCamera",
							Math.PI / 4,
							Math.PI / 4,
							10,
							new BABYLON.Vector3(98.92, 46.94, 282.67),
							scene
						);
						ArcCamera.upperRadiusLimit = 300;
						ArcCamera.lowerRadiusLimit = 300;
						ArcCamera.minZ = 0.5;
						ArcCamera.fov = 0.5;
						ArcCamera.useAutoRotationBehavior = true;
						cameraArcRef.current = ArcCamera;

						const ArcComCamera = new BABYLON.ArcRotateCamera(
							"RotateComponentCamera",
							Math.PI / 4,
							Math.PI / 4,
							10,
							BABYLON.Vector3.Zero(),
							scene
						);
						ArcComCamera.upperRadiusLimit = 300;
						ArcComCamera.lowerRadiusLimit = 1;
						ArcComCamera.minZ = 0.1;
						cameraComRef.current = ArcComCamera;

						return scene;
					};

					const scene = createScene();
					sceneRef.current = scene;
					engineRef.current = engine;

					if (scene.isReady()) {
						onSceneReady(scene);
					} else {
						scene.onReadyObservable.addOnce(() => {
							onSceneReady(scene);
						});
					}

					engine.runRenderLoop(function () {
						scene.render();
					});

					window.addEventListener("resize", function () {
						engine.resize();
					});
				})
				.catch((error: any) => {
					console.log("fail to initialize WebGPU engine, changing to WebGL");
					localStorage.setItem("graphicsEngine", "WebGL");
					setGraphicEngine("WebGL");
				});
		} else if (!acceptedroles) {
		}
	}, [acceptedroles]);

	useEffect(() => {
		startUp();
		if (sceneRef.current) {
			clearEverything();
			pureReset();
		}
		axios
			.get(`${url}/models/hierarchy/maximo-list-900.json`)
			.then((result: any) => {
				//console.log(result.data);
				let filteredTree = result.data.filter((element: any) => {
					return !element.key.startsWith("1");
				});
				setAssetHierarchy(filteredTree || {});

				const flattenTree = (tree: any) => {
					const result: any = [];
					const recursiveFlatten = (node: any) => {
						const titleArray: Array<string> = node.title
							.trim()
							.split(/[\s+-]/)
							.filter((v: any) => v);
						// titleArray.splice(0, 1);
						const cleanedTitle = titleArray.join(" ");
						result.push(`${cleanedTitle} (${node.key}) `);
						if (node.children) {
							node.children.forEach((child: any) => recursiveFlatten(child));
						}
					};

					tree.forEach((node: any) => recursiveFlatten(node));
					return result;
				};
				let flat = flattenTree(filteredTree);
				setFlatAssetHierarchy(flat);
			})
			.catch((error: any) => {
				console.log(error);
			});
		axios
			.get(`${url}/models/hierarchy/test.json`)
			.then((result: any) => {
				//console.log(result.data);
				setAvailableAssets(result.data || {});
				// setHierarchyTree(tree);
			})
			.catch((error: any) => {
				console.log(error);
			});

		duckQuery(
			null,
			true,
			"SELECT * FROM pob WHERE pob_date = ((SELECT MAX(pob_date) FROM pob WHERE vessel_id = '900')) and vessel_id = '900' ORDER BY company DESC"
		)
			.then((data: any) => {
				console.log(data);
				if (data?.response?.length) {
					const pobData = data?.response.sort((a: any, b: any) => {
						return a.occupation.localeCompare(b.occupation);
					});
					setPobData(pobData);

					duckQuery(crewModelDeltaQueryOptions)
						.then((data: any) => {
							console.log(data);
							if (data?.response?.length) {
								let PobTree = traversePobHierarchy(data?.response, pobData);
								setPobHierarchy(PobTree);
							} else {
							}
						})
						.catch((error) => {
							console.error(error);
						});
				} else {
					// setData([]);
				}
			})
			.catch((error) => {
				console.log(error);
				console.error(error);
			});
	}, [location, graphicalEngine]);

	const traversePobHierarchy = (data: any, pobData: any) => {
		let tree: any = {
			title: "Stena Drilling",
			key: "stenadrilling",
			selectable: false,
			children: [],
		};
		let occupationCount: any = {};
		const items: any = {
			occupation: "-",
			area: "-",
			full_name: "-",
			email: "-",
			hierarchy: "-",
			arrival_date: "-",
			departure_date: "TBC",
			days_on_rig: 0,
			cabin: "-",
			lifeboat_name: "-",
			company_contact_no: "-",
			emergency_team: "-",
		};
		console.log(data, pobData);

		const levenshteinSimilarity = (str1: any, str2: any) => {
			if (str1.length === 0 && str2.length === 0) {
				return 100.0;
			} else if (str1.length === 0 || str2.length === 0) {
				return 0.0;
			}

			const dp = new Array(str1.length + 1)
				.fill(0)
				.map(() => new Array(str2.length + 1).fill(0));

			for (let i = 0; i <= str1.length; i++) {
				dp[i][0] = i;
			}

			for (let j = 0; j <= str2.length; j++) {
				dp[0][j] = j;
			}

			for (let i = 1; i <= str1.length; i++) {
				for (let j = 1; j <= str2.length; j++) {
					const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
					dp[i][j] = Math.min(
						dp[i - 1][j] + 1,
						dp[i][j - 1] + 1,
						dp[i - 1][j - 1] + cost
					);
				}
			}

			const maxLen = Math.max(str1.length, str2.length);
			const similarity =
				((maxLen - dp[str1.length][str2.length]) / maxLen) * 100.0;

			return similarity;
		};

		const createChildNode = (
			parent: any,
			key: any,
			elementNode: any,
			isOccupation: any = false
		) => {
			let childNode = parent.children.find((node: any) => node.key === key);

			if (!isOccupation) {
				if (!childNode) {
					childNode = {
						title: key,
						key,
						selectable: isOccupation,
						children: [],
					};
					parent.children.push(childNode);
				}
			} else if (isOccupation) {
				key = elementNode.occupation.split("-").join(" ");

				if (!occupationCount[key]) {
					occupationCount[key] = 1;
				} else {
					occupationCount[key] += 1;
				}

				const customOccupation: any = {
					Medic: "Doctor",
				};

				let data: any = { ...items };
				let selectedPobData: any = {};
				let originalOccupation = key;
				let selectedOccupation =
					customOccupation[originalOccupation] || originalOccupation;
				let occupationIndex = occupationCount[key];

				let highestSimilarityString: any = "";
				let similarityIndex: any = 0;
				let pobList: any = pobData
					.filter((element: any) => {
						if (element.occupation.includes(selectedOccupation)) {
							return true;
						} else {
							let similarity = levenshteinSimilarity(
								element.occupation,
								selectedOccupation
							);

							if (similarity > 80) {
								return true;
							}
						}
					})
					.map((element: any) => {
						let similarity = levenshteinSimilarity(
							element.occupation,
							selectedOccupation
						);

						if (similarity > similarityIndex) {
							similarityIndex = similarity;
							highestSimilarityString = element.occupation;
						}
						return element;
					})
					.filter((element: any) => {
						return element.occupation === highestSimilarityString;
					});

				if (pobList.length > 0) {
					selectedPobData = pobList[occupationIndex - 1] || {};
				}

				Object.keys(items).map((element: any) => {
					data[element] = selectedPobData[element] || items[element];
				});

				let arrival_date = new Date(data.arrival_date);
				let departure_date = new Date(data.departure_date);
				let days_on_rig = data.days_on_rig;
				let today = new Date();

				if (arrival_date.toString() != "Invalid Date") {
					data.arrival_date = new Date(data.arrival_date).toLocaleDateString(
						"en-GB",
						{
							day: "2-digit",
							year: "numeric",
							month: "long",
						}
					);
				}

				if (departure_date.toString() != "Invalid Date") {
					data.departure_date = new Date(
						data.departure_date
					).toLocaleDateString("en-GB", {
						day: "2-digit",
						year: "numeric",
						month: "long",
					});
				}

				if (days_on_rig == "0" && arrival_date.toString() != "Invalid Date") {
					const time = today.getTime() - arrival_date.getTime();
					data.days_on_rig = time / (1000 * 60 * 60 * 24);
				}

				childNode = {
					...data,
					title: key,
					key: key + "_" + occupationCount[key],
					selectable: isOccupation,
					children: [],
					email: elementNode.Email,
					area: elementNode.area,
					hierarchy: elementNode.Hierarchy,
				};
				parent.children.push(childNode);
			}
			return childNode;
		};

		data.forEach((element: any) => {
			const functionNode = createChildNode(tree, element.function, element);
			createChildNode(functionNode, element.occupation, element, true);
		});

		return [tree];
	};

	useEffect(() => {
		// Genenal keyboard checker
		const handleKeyDown = (event: any) => {
			if (event.ctrlKey && event.key === "f") {
				event.preventDefault(); // Prevent the default browser search behavior
				searchInputRef.current.focus(); // Focus on the custom search bar
			}
		};

		// Handle mouse leave to check
		const handleMouseLeave = (event: any) => {
			setTimeout(() => {
				showPopover(null, false);
				removeHighlightedAsset();
			}, 500);
		};

		// Handle mouse click to check if the inspector window is missing or not
		// to change the state of the menukeys
		const handleMouseClick = (event: any) => {
			if (
				!document.getElementById("embed-host") &&
				isInspectorOpenRef.current
			) {
				setMenuKey(null);
			}
		};

		if (isLoaded) {
			canvasRef.current.focus();
			let randomValue = 40;
			// animateBox("StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI2_101_A00", 40);

			// intervalRef.current.push(
			// 	setInterval(() => {
			// 		randomValue = randomValue == 40 ? 0 : 40;
			// 		animateBox(
			// 			"StenaCarron.OPC UA.NOV.2.DataVault.PubSub.SDI2_101_A00",
			// 			randomValue
			// 		);
			// 	}, 15000)
			// );

			socket.emit("DigitalTwin");

			socket.on("Carron", (element: any) => {
				element = JSON.parse(element);
				if (kognifai_data_series.includes(element.Name)) {
					// console.log("Animating", element.Name, element.Value);
					animateBox(element.Name, element.Value, 3);
				}
				let index = mqttRef.current.findIndex((_element: any) => {
					return element.Name === _element.Name;
				});
				if (index == -1) {
					mqttRef.current.push(element);
				} else {
					mqttRef.current[index] = element;
				}

				if (menuKeyRef.current == "MQTT") {
					setMqttList([...mqttRef.current]);
				}
			});

			canvasRef.current.addEventListener("mouseleave", handleMouseLeave);

			document.addEventListener("keydown", handleKeyDown);
			document.addEventListener("click", handleMouseClick);

			return () => {
				document.removeEventListener("keydown", handleKeyDown);
				document.onmousemove = null;
				if (canvasRef.current) {
					canvasRef.current.removeEventListener("mouseleave", handleMouseLeave);
				}
			};
		}
	}, [isLoaded]);

	useEffect(() => {
		if (selectValue && menuKey === "Asset") {
			const assetNode: any = handleSearch(selectValue + "_Asset", true);
			if (assetNode.length > 0) {
				setExpandedKeys(assetNode[0].treePos.split("-"));

				const treeInstance = assetTreeRef.current;
				if (treeInstance) {
					setTimeout(() => {
						var element: any = document.getElementById(selectValue);
						//console.log(element);
						if (element)
							element.scrollIntoView({
								behavior: "smooth",
								block: "nearest",
								inline: "start",
							});
						setTimeout(() => {
							handleAssetTreeSelect([assetNode[0].key], assetNode[0]);
						}, 1000);
					}, 500);
				}
			}
		}
	}, [selectValue, menuKey]);

	useEffect(() => {
		if (scene) {
			document.addEventListener("pointerlockchange", (event: any) => {
				if (document.pointerLockElement === null) {
					Pointerlock.current = false;
					crosshairRef.current.isVisible = false;
					spaceKeyDown = false;
				}
			});
			sceneRef.current = scene;
			const hl = new BABYLON.HighlightLayer("hl", scene);
			setModelLoadDesc("Adding Skybox");
			setModelLoaded(10);

			Object.keys(originalClipPlanes).forEach((element: any) => {
				scene[element] = originalClipPlanes[element];
			});

			// Disable the default audio unlock button
			// let audioEngine: any = BABYLON.Engine.audioEngine;
			// audioEngine.useCustomUnlockedButton = true;

			addSkybox(scene).then((skybox: any) => {
				setModelLoadDesc("Adding Ocean");
				setModelLoaded(20);
				LoadOcean(scene, skybox).then((ocean: any) => {
					setModelLoadDesc("Downloading Vessel Model");
					setModelLoaded(30);
					loadModel(scene, hl).then((res: any) => {
						let meshes = sceneRef.current.meshes;
						let currentMeshIndex = 0;

						let loadInterval = setInterval(function () {
							if (currentMeshIndex < meshes.length && defaultInvisibleAsset) {
								// Show the current mesh part
								meshes[currentMeshIndex].isVisible = true;
								currentMeshIndex++;
								let loaded = (
									70 +
									(currentMeshIndex / meshes.length) * 20
								).toFixed(2);
								setModelLoaded(
									(70 + (currentMeshIndex / meshes.length) * 20).toFixed(2)
								);
								if (loaded === "90.00") {
								}
							} else {
								// All mesh parts have been loaded, clear the interval
								clearInterval(loadInterval);
								setModelLoadDesc("Finalizing Model");

								if (scene.activeCamera) {
									let crosshair = addCrosshair(scene);
									crosshair.renderingGroupId = 2;
									hl.addExcludedMesh(crosshair);
									crosshairRef.current = crosshair;
									// console.log(crosshair);
									var camera = scene.activeCamera;
									scene.activeCamera.detachControl();
									cameraRef.current.detachControl();
									cameraArcRef.current.detachControl();
									cameraComRef.current.detachControl();
									scene.activeCamera = cameraRef.current;
									scene.activeCamera.attachControl(canvas, false);
									activeCameraRef.current = scene.activeCamera;
									// Set the camera's collision group and mask
									//V1 box size 100 - follows camera
									var box = BABYLON.MeshBuilder.CreateBox(
										"Box",
										{ size: 100 },
										scene
									);

									//V2 box size 30 - depending on where the user clicks
									// var box = BABYLON.MeshBuilder.CreateBox(
									// 	"Box",
									// 	{ size: 30 },
									// 	scene
									// );
									box.position = camera.position;
									box.isVisible = false;
									box.isPickable = false;
									box.checkCollisions = false;
									box.renderingGroupId = 2;
									// box.visibility = 0.3;
									boxRef.current = box;

									var smallBox = BABYLON.MeshBuilder.CreateBox(
										"smallBox",
										{ size: 1 },
										scene
									);

									smallBox.position = camera.position;
									smallBox.isVisible = false;
									smallBox.isPickable = false;
									smallBox.checkCollisions = false;
									smallBox.renderingGroupId = 2;
									smallBoxRef.current = smallBox;

									// Unlock audio on first user interaction.
									// window.addEventListener(
									// 	"click",
									// 	() => {
									// 		if (!audioEngine.unlocked) {
									// 			audioEngine.unlock();
									// 		}
									// 	},
									// 	{ once: true }
									// );

									camera.keysUpward.push(69); //increase elevation
									camera.keysDownward.push(81); //decrease elevation
									camera.keysUp.push(87); //forwards
									camera.keysDown.push(83); //backwards
									camera.keysLeft.push(65);
									camera.keysRight.push(68);

									cameraComRef.current.inputs.attached.keyboard.keysLeft.push(
										65
									); // A key (rotate counterclockwise)
									cameraComRef.current.inputs.attached.keyboard.keysRight.push(
										68
									); // D key (rotate clockwise)

									if (tutorial) {
										setTutorialModal(true);
									}

									getKognifaiData(kognifai_data_series, "5s", "carron")
										.then((kognifaiArr: any) => {
											if (kognifaiArr.length > 0) {
												kognifaiArr?.forEach((element: any) => {
													animateBox(
														element.series,
														new Number(element.Value),
														50
													);
												});
											}
										})
										.catch((err: any) => {
											console.log(err);
										});

									// addParticles();

									// 	var result = new BABYLON.SceneOptimizerOptions(60, 2000);

									// 	var priority = 0;
									// 	result.optimizations.push(
									// 		new BABYLON.ShadowsOptimization(priority)
									// 	);
									// 	result.optimizations.push(
									// 		new BABYLON.LensFlaresOptimization(priority)
									// 	);

									// 	// Next priority
									// 	priority++;
									// 	result.optimizations.push(
									// 		new BABYLON.PostProcessesOptimization(priority)
									// 	);
									// 	result.optimizations.push(
									// 		new BABYLON.ParticlesOptimization(priority)
									// 	);
									// 	result.optimizations.push(
									// 		new BABYLON.HardwareScalingOptimization(priority, 0.75)
									// 	);
									// 	// ();

									// 	// Next priority
									// 	priority++;
									// 	// result.optimizations
									// 	// 	.push
									// 	// 	// new BABYLON.HardwareScalingOptimization(priority, 2.5)
									// 	// 	();
									// 	result.optimizations.push(
									// 		new BABYLON.TextureOptimization(priority, 32)
									// 	);

									// 	// Next priority
									// 	priority++;
									// 	// result.optimizations
									// 	// 	.push
									// 	// 	// new BABYLON.HardwareScalingOptimization(priority, 3)
									// 	// 	();
									// 	// result.optimizations.push(
									// 	// 	new BABYLON.RenderTargetsOptimization(priority)
									// 	// );

									// 	// Next priority
									// 	priority++;
									// 	result.optimizations.push(
									// 		new BABYLON.HardwareScalingOptimization(priority, 3)
									// 	);

									// 	result.addCustomOptimization(
									// 		function () {
									// 			setOptions({ ...options, enableHighPoly: true });
									// 			highPolyMeshes.current.forEach((element: any) => {
									// 				element.setEnabled(false);
									// 			});
									// 			return true;
									// 		},
									// 		function () {
									// 			return "Turning Off HighPolyMeshes";
									// 		}
									// 	);

									// 	BABYLON.SceneOptimizer.OptimizeAsync(
									// 		scene,
									// 		result,
									// 		function () {
									// 			console.log("success");
									// 		},
									// 		function () {
									// 			// FPS target not reached
									// 			console.log("fail");
									// 		}
									// 	);
									setModelLoaded(100);
									setLoaded(true);
								}
							}
						}, 1);
					});
				});
			});
			// setupPointerLock();
			scene.getEngine().resize();
			scene.setRenderingAutoClearDepthStencil(2, false, false, false);
		}
	}, [scene]);

	useEffect(() => {
		return () => {
			// console.log("CLEAR");
			clearEverything();
		};
	}, []);

	useEffect(() => {
		if (pickables.current) {
			addPointerObservable();
			addKeyboardObservable();
			addAfterRenderObservable();
		}
	}, [isLoaded]);

	const clearEverything = () => {
		sceneRef.current.meshes.forEach((mesh: any) => {
			mesh.dispose();
		});

		// clear all materials from the scene
		sceneRef.current.materials.forEach((material: any) => {
			material.dispose();
		});

		// clear all textures from the scene
		sceneRef.current.textures.forEach((texture: any) => {
			texture.dispose();
		});

		animationGroupRef.current.forEach((animation: any) => {
			animation.dispose();
		});

		intervalRef.current.forEach((interval: any) => {
			clearInterval(interval);
		});
		sceneRef.current.dispose();
		engineRef.current.stopRenderLoop();
		engineRef.current.dispose();
	};

	const pureReset = () => {
		setLeftDrawer(false);
		setRightDrawer(false);
		setMenuKey(null);
		setMaximoData({});
		setMaximoView(false);
		setSelectedKeys([]);
		setSelectValue(null);
		setCameraMode("1");

		isFocusedRef.current = false;
		oceanRef.current.isVisible = true;
		skyRef.current.isVisible = true;
		level2Ref.current = null;
		level3Ref.current = null;
		level1Ref.current = null;

		cameraRef.current.position = defaultPosition.clone();
		cameraRef.current.target = defaultTarget.clone();
		activeCameraRef.current = scene.activeCamera;
		if (Pointerlock.current) {
			document.exitPointerLock();
			Pointerlock.current = false;
		}

		setLoaded(false);
		setModelLoaded(0);
		setModelLoadDesc("Initialize engine");
	};

	const ResetEverything = () => {
		scene.activeCamera.detachControl();
		scene.activeCamera = cameraRef.current;
		//console.log(cameraRef.current);
		scene.activeCamera.attachControl(canvas, false);
		AllMeshes.current.map((element: any) => {
			element.setEnabled(true);
		});
		if (engineerMesh.current.length > 0) {
			engineerMesh.current.map((element: any) => {
				element.dispose();
			});
			engineerMesh.current = [];
		}

		setMaximoData({});
		setMaximoView(false);
		isFocusedRef.current = false;
		setRightDrawer(false);

		setSelectedKeys([]);
		setSelectValue(null);
		setIsSearch(false);
		setSearchResults([]);
		setCameraMode("1");
		oceanRef.current.isVisible = true;
		skyRef.current.isVisible = true;
		level2Ref.current = null;
		level3Ref.current = null;
		level1Ref.current = null;

		cameraRef.current.position = defaultPosition.clone();
		cameraRef.current.target = defaultTarget;
		activeCameraRef.current = scene.activeCamera;
		if (Pointerlock.current) {
			document.exitPointerLock();
			Pointerlock.current = false;
		}
		canvasRef.current.focus();
	};

	const addPointerObservable = () => {
		let wheelTimer: any = null;
		scene.onPointerObservable.add((pointerInfo: any) => {
			const button: any = pointerInfo.event.button;
			switch (pointerInfo.type) {
				case BABYLON.PointerEventTypes.POINTERTAP:
					switch (button) {
						case 0:
							//console.log(pointerInfo);
							onPointerLeftClick(scene, pointerInfo);
							break;
						case 1:
							//console.log("MIDDLE");
							//console.log(closeMeshRef.current);
							break;
						case 2:
							if (isFocusedRef.current) {
								showPopover("", false);
								if (level3Ref.current) {
									// level3Ref.current.renderOutline = false;
									highlightLayer.addExcludedMesh(level3Ref.current);
									level3Ref.current = null;
									engineerMesh.current.forEach((element: any) => {
										element.visibility = 1;
									});
									setMaximoData({});
									setRightDrawer(false);
									setMaximoView(false);

									if (level2Ref.current) {
										let assetnum = level2Ref.current
											.replace("P.", "")
											.trim()
											.split(/[-\s]/)[0];

										setRightDrawer(true);
										setMaximoLoading(true);
										setMaximoView(true);
										getAssetData(assetnum);
										setSelectedKeys([assetnum]);
										handleSearch(assetnum + "_Asset");
									}
								} else {
									//ComponentArcCamera
									scene.activeCamera.detachControl();

									scene.activeCamera = activeCameraRef.current;
									if (activeCameraRef.current === cameraRef.current)
										scene.activeCamera.attachControl(canvas, false);
									scene.activeCamera.position =
										activeCameraRef.current.position;
									scene.activeCamera.target = activeCameraRef.current.target;

									AllMeshes.current.map((element: any) => {
										element.setEnabled(true);
									});
									engineerMesh.current.map((element: any) => {
										highlightLayer.removeExcludedMesh(element);
										highlightLayer.removeMesh(element);
										element.dispose();
									});
									engineerMesh.current = [];
									setMaximoData({});
									setMaximoView(false);
									isFocusedRef.current = false;
									setRightDrawer(false);
									setSelectedKeys([]);
									setExpandedKeys([]);
									setSelectValue(null);
									setSearchResults([]);
									setIsSearch(false);
									oceanRef.current.isVisible = true;
									skyRef.current.isVisible = true;
									level3Ref.current = null;
									level2Ref.current = null;
								}
							}

							break;
					}
					break;
				case BABYLON.PointerEventTypes.POINTERWHEEL:
					var scrollDelta = pointerInfo.event.wheelDelta;
					if (
						!level2Ref.current &&
						scene.activeCamera != cameraArcRef.current
					) {
						let fov = cameraRef.current.fov;
						if (mouseHoldRef.current == 0) {
							if (scrollDelta > 0) {
								fov += 0.05;
								if (fov > 1.5) {
									fov = 1.5;
								}
							} else {
								fov -= 0.05;
								if (fov < 0.05) {
									fov = 0.05;
								}
							}
							cameraRef.current.fov = Number(fov.toFixed(2));
							if (menuKeyRef.current === "Help") {
								setCameraFov(cameraRef.current.fov);
							}
						} else {
							var scrollDelta = pointerInfo.event.wheelDelta;
							let speed = cameraRef.current.speed;
							if (scrollDelta > 0) {
								speed += 0.5;
							} else {
								speed -= 0.5;
								if (speed < 0.05) {
									speed = 0.05;
								}
							}
							cameraRef.current.speed = Number(speed.toFixed(2));
							if (menuKeyRef.current === "Help") {
								setCameraSpeed(cameraRef.current.speed);
							}
						}
					}

					const wheelKey = document.getElementById(`1-Highlight`);

					if (wheelKey) {
						wheelKey.style.opacity = "1";
					}

					clearTimeout(wheelTimer);

					// Start a new wheel timer
					wheelTimer = setTimeout(() => {
						const wheelKey = document.getElementById(`1-Highlight`);

						if (wheelKey) {
							wheelKey.style.opacity = "0";
						}
					}, 100); // Adjust the delay as per your requirements

					break;
				case BABYLON.PointerEventTypes.POINTERMOVE:
					if (movementRef.current.length == 0 && !downloadingRef.current) {
						highlightMesh(scene);
					}

					break;

				case BABYLON.PointerEventTypes.POINTERDOWN:
					pointerClickRef.current = false;
					if ([0, 1, 2].includes(button)) {
						const wheelKey = document.getElementById(`${button}-Highlight`);

						if (wheelKey) {
							wheelKey.style.opacity = "1";
						}
					}
					switch (button) {
						case 0:
							mouseHoldRef.current = 0;
							//console.log("Holding left button");
							break;
					}
					break;

				case BABYLON.PointerEventTypes.POINTERUP:
					pointerClickRef.current = true;
					if ([0, 1, 2].includes(button)) {
						const wheelKey = document.getElementById(`${button}-Highlight`);

						if (wheelKey) {
							wheelKey.style.opacity = "0";
						}
					}
					switch (button) {
						case 0:
							mouseHoldRef.current = null;
							//	console.log("Release left button");
							break;
					}
					break;
			}
		});
	};

	const addKeyboardObservable = () => {
		scene.onKeyboardObservable.add((keyboardInfo: any) => {
			const key = keyboardInfo.event.key.toLowerCase();

			const scene = sceneRef.current;
			showPopover(null, false);

			if (keyboardInfo.type === BABYLON.KeyboardEventTypes.KEYDOWN) {
				if (
					!movementRef.current.find((element: any) => element === key) &&
					!["alt", "tab", "ctrl", "meta"].includes(key)
				)
					movementRef.current.push(key);
				if (key === " " && scene.activeCamera.name == cameraRef.current.name) {
					if (spaceKeyDown === false) {
						if (Pointerlock.current) {
							document.exitPointerLock();
							Pointerlock.current = false;
							spaceKeyDown = true;
						} else {
							if (document.pointerLockElement === null) {
								canvasRef.current.requestPointerLock({
									unadjustedMovement: true,
								});
								spaceKeyDown = true;
								Pointerlock.current = true;
								crosshairRef.current.isVisible = true;
							}
						}
					}
				}

				if (key === "shift") {
					if (shiftKeyDown === false) {
						cameraRef.current.speed = Number(
							(cameraRef.current.speed += 2).toFixed(2)
						);
						shiftKeyDown = true;
					}
				}
				if (key === "escape") {
					ResetEverything();
				}

				//QE = Active - #BFBFBF #434343 #595959 | Inactive - #434343 #434343 #141414
				//WASD = Active - #3C9AE8 #1765AD #112A45 | Inactive - #1765AD #1765AD #141414
				if (["w", "a", "s", "d"].includes(key)) {
					//console.log("WASD");
					const keyboardKey = document.getElementById(`${key}Key`);
					const text = document.getElementById(`${key}Text`);

					if (keyboardKey && text) {
						keyboardKey.style.fill = "#112A45";
						keyboardKey.style.stroke = "#1765AD";
						text.style.fill = " #3C9AE8";
					}

					if (isFocusedRef.current) {
						if (key == "w") {
							cameraComRef.current.radius -= 3;
						} else if (key == "s") {
							cameraComRef.current.radius += 3;
						}
					}
				}
				if (["q", "e"].includes(key)) {
					const keyboardKey = document.getElementById(`${key}Key`);
					const text = document.getElementById(`${key}Text`);

					if (keyboardKey && text) {
						keyboardKey.style.fill = "#595959";
						keyboardKey.style.stroke = "#434343";
						text.style.fill = "#BFBFBF";
					}

					if (isFocusedRef.current) {
						if (key == "e") {
							cameraComRef.current.target.y += 1;
						} else if (key == "q") {
							cameraComRef.current.target.y -= 1;
						}
					}
				}
			}
			if (keyboardInfo.type === BABYLON.KeyboardEventTypes.KEYUP) {
				let index = movementRef.current.findIndex(
					(element: any) => element === key
				);
				if (index >= 0)
					movementRef.current = movementRef.current.slice(0, index);
				if (key === "shift") {
					if (shiftKeyDown === true) {
						cameraRef.current.speed = Number(
							(cameraRef.current.speed -= 2).toFixed(2)
						);
						shiftKeyDown = false;
					}
				}
				if (key === " ") {
					spaceKeyDown = false;
				}
				if (["w", "a", "s", "d"].includes(key)) {
					const keyboardKey = document.getElementById(`${key}Key`);
					const text = document.getElementById(`${key}Text`);

					if (keyboardKey && text) {
						keyboardKey.style.fill = "#141414";
						keyboardKey.style.stroke = "#1765AD";
						text.style.fill = "ws#1765AD";
					}
				}
				if (["q", "e"].includes(key)) {
					const keyboardKey = document.getElementById(`${key}Key`);
					const text = document.getElementById(`${key}Text`);

					if (keyboardKey && text) {
						keyboardKey.style.fill = "#141414";
						keyboardKey.style.stroke = "#434343";
						text.style.fill = "#434343";
					}
				}
			}
		});
	};

	const addAfterRenderObservable = () => {
		scene.onAfterRenderObservable.add(async () => {
			let closeMeshes: any = [];

			//V1 - box following camera
			boxRef.current.position = cameraRef.current.position;
			if (pickables.current) {
				await Promise.all(
					pickables.current.map((mesh: any) => {
						if (mesh.intersectsMesh(boxRef.current, true)) {
							closeMeshes.push(mesh);
						} else {
							// highlightLayer.addExcludedMesh(mesh);
							// mesh.renderOutline = false;
						}
					})
				);
				closeMeshRef.current = closeMeshes;
			}

			smallBoxRef.current.position = cameraRef.current.position;
			const boxBounding = smallBoxRef.current.getBoundingInfo().boundingBox;
			const min = boxBounding.minimumWorld;
			const max = boxBounding.maximumWorld;

			if (hullRef.current && !isFocusedRef.current) {
				let check = hullRef.current.some((element: any) => {
					if (element.intersectsMinMax(min, max)) {
						return true;
					} else {
						return false;
					}
				});

				if (check && smallBoxRef.current.isEnabled()) {
					oceanRef.current.isVisible = false;
				} else {
					oceanRef.current.isVisible = true;
				}
			}
		});
	};
	const getAssetData = (assetnum: any) => {
		getMaximoData(assetnum, "900")
			.then((data: any) => {
				if (data.location == null) {
					getMaximoData(assetnum, "900", true)
						.then((data: any) => {
							console.log(data);
							setMaximoData({
								"Asset Number": assetnum || "null",
								Description: data.description || "null",
								Parent: data.parent || "null",
								Location: data.location || "null",
								Status: data.status || "null",
								"status Date": data.statusdate || "null",
							});
							setMaximoLoading(false);
						})
						.catch(() => {
							console.log("Cannot fetch Maximo Data");
							setMaximoData({});
							// isFocusedRef.current = true;
							setMaximoLoading(false);
						});
				} else {
					setMaximoData({
						"Asset Number": assetnum || "null",
						Description: data.description || "null",
						Parent: data.parent || "null",
						Location: data.location || "null",
						Status: data.status || "null",
						"status Date": data.statusdate || "null",
					});
					setMaximoLoading(false);
				}
			})
			.catch(() => {
				console.log("Failure: Cannot fetch Maximo Data");
				setMaximoData({});
				setMaximoLoading(false);
			});
	};

	const changeCamera = (CameraNumber: string) => {
		scene.activeCamera.detachControl();
		if (CameraNumber != "2") {
			scene.activeCamera = cameraRef.current;
			scene.activeCamera.attachControl(canvas, false);
			smallBoxRef.current.setEnabled(true);
		} else if (CameraNumber == "2") {
			scene.activeCamera = cameraArcRef.current;
			scene.activeCamera.autoRotationBehavior.idleRotationSpinupTime = 2000;
			scene.activeCamera.autoRotationBehavior.idleRotationWaitTime = 100;
			scene.activeCamera.autoRotationBehavior.idleRotationSpeed = 0.1;
			smallBoxRef.current.setEnabled(false);
			showPopover(null, false);
			removeHighlightedAsset();
			// scene.activeCamera.attachControl(canvas, false, false, true);
		}

		// scene.activeCamera.position = position;

		if (CameraNumber == "2") {
			scene.activeCamera.setTarget(new BABYLON.Vector3(100, 40, 0));
			scene.activeCamera.rebuildAnglesAndRadius();
		}
		if (CameraNumber == "3") {
			oceanRef.current.isVisible = false;
			skyRef.current.isVisible = false;
		}

		if (Pointerlock.current) {
			document.exitPointerLock();
			Pointerlock.current = false;
		}
		activeCameraRef.current = scene.activeCamera;
	};

	const addCrosshair = (scene: any) => {
		let w = 128;

		let texture = new BABYLON.DynamicTexture("reticule", w, scene, false);
		texture.hasAlpha = true;

		let ctx = texture.getContext();
		let reticule;

		const createNavigate = () => {
			ctx.fillStyle = "transparent";
			ctx.clearRect(0, 0, w, w);
			ctx.strokeStyle = "rgba(170, 255, 0, 0.9)";
			ctx.lineWidth = 3.5;
			ctx.beginPath();
			ctx.arc(w * 0.5, w * 0.5, 20, 0, 2 * Math.PI);
			ctx.fillStyle = "rgba(0, 255, 0, 0.4)";
			ctx.fill();
			ctx.stroke();

			texture.update();
		};
		createNavigate();

		let material = new BABYLON.StandardMaterial("reticule", scene);
		material.diffuseTexture = texture;
		material.opacityTexture = texture;
		material.emissiveColor.set(1, 1, 1);
		material.disableLighting = true;

		let plane = BABYLON.MeshBuilder.CreatePlane(
			"reticule",
			{ size: 0.04 },
			scene
		);
		plane.material = material;
		plane.position.set(0, 0, 1.1);
		plane.isPickable = false;
		plane.rotation.z = Math.PI / 4;

		reticule = plane;
		reticule.parent = scene.activeCamera;
		reticule.isVisible = false;
		return reticule;
	};

	const onPointerLeftClick = (scene: any, pointerInfo: any) => {
		// console.log("Clicking");
		//V1 PointerClick
		var res: any = null;
		if (scene.activeCamera !== cameraArcRef.current) {
			if (Pointerlock.current) {
				var ray = scene.activeCamera.getForwardRay(200);
				res = scene.pickWithRay(ray);
			} else {
				res = scene.pick(scene.pointerX, scene.pointerY);
			}

			if (res.hit) {
				const regex = /\([^)]*\)/;

				// Replace the matched text with an empty string
				const resultString = res.pickedMesh.name.replace(regex, "");

				let assetnum = resultString.replace("P.", "").trim().split(/[-\s]/)[0];
				setSelectedKeys([assetnum]);

				if (
					highlightedAssetRef.current[0]?.name.includes(
						res.pickedMesh.name.split("_primitive")[0]
					)
				) {
					showPopover(null, false);
					if (level2Ref.current) {
						if (level3Ref.current != res.pickedMesh) {
							setRightDrawer(true);
							setMaximoLoading(true);
							setMaximoView(true);
							getAssetData(assetnum);
							engineerMesh.current.forEach((mesh: any) => {
								mesh.visibility = 0.03;
							});
							if (level3Ref.current) {
								level3Ref.current.renderOutline = false;
								highlightLayer.addExcludedMesh(level3Ref.current);
							}

							highlightedAssetRef.current = [];
							highlightLayer.removeMesh(res.pickedMesh);
							highlightLayer.addMesh(
								res.pickedMesh,
								BABYLON.Color3.FromHexString("#7DF9FF")
							);
							level3Ref.current = res.pickedMesh;
							highlightLayer.removeExcludedMesh(res.pickedMesh);
							res.pickedMesh.visibility = 1;
							handleSearch(assetnum + "_Asset");
						}
					} else {
						LoadComponent(scene, resultString);
					}
				}
			}
		}

		//V2 PointerClick (set boxMesh collider to clicked position)
		// var res: any = null;
		// if (Pointerlock.current) {
		// 	var ray = scene.activeCamera.getForwardRay(200);
		// 	res = scene.pickWithRay(ray);
		// } else {
		// 	res = scene.pick(scene.pointerX, scene.pointerY);
		// }
		// console.log(pointerInfo.pickInfo?.pickedPoint);
		// if (pointerInfo.pickInfo?.pickedPoint)
		// 	boxRef.current.position = pointerInfo.pickInfo?.pickedPoint;
	};
	const removeHighlightedAsset = () => {
		if (highlightedAssetRef?.current?.length > 0) {
			highlightedAssetRef?.current.forEach((element: any) => {
				highlightLayer.addExcludedMesh(element);
				// element.renderOutline = false;
			});
			highlightedAssetRef.current = [];
		}
	};

	const highlightMesh = (scene: any) => {
		clearTimeout(pointerMoveTimerID);
		if (pointerClickRef.current) {
			pointerMoveTimerID = setTimeout(() => {
				// V1 Highlight mesh on pointerHover
				// Very expensive if there are a lot of pickables
				if (scene.activeCamera !== cameraArcRef.current) {
					var res: any = null;
					if (Pointerlock.current) {
						var ray = scene.activeCamera.getForwardRay(200);
						res = scene.pickWithRay(ray);
					} else {
						res = scene.pick(scene.pointerX, scene.pointerY);
					}

					if (res.hit) {
						const real = res.pickedMesh.name.split("_primitive")[0];

						if (isFocusedRef.current) {
							if (
								!highlightedAssetRef.current[0]?.name.includes(
									res.pickedMesh
								) &&
								// res.pickedMesh.renderOutline != true &&
								level3Ref.current !== res.pickedMesh
							) {
								showPopover(res.pickedMesh.name, true);
								if (highlightedAssetRef?.current.length > 0) {
									removeHighlightedAsset();
								}
								highlightLayer.removeExcludedMesh(res.pickedMesh);
								highlightLayer.addMesh(
									res.pickedMesh,
									BABYLON.Color3.FromHexString("#7DF9FF")
								);
								// res.pickedMesh.renderOutline = true;

								highlightedAssetRef.current = [res.pickedMesh];
							}
						} else if (closeMeshRef.current.length > 0) {
							if (
								closeMeshRef.current.includes(res.pickedMesh) &&
								// res.pickedMesh.renderOutline != true &&
								!highlightedAssetRef.current[0]?.name.includes(res.pickedMesh)
							) {
								if (res.pickedMesh.name.includes("_primitive")) {
									showPopover(real, true);
									if (highlightedAssetRef?.current.length > 0) {
										removeHighlightedAsset();
									}

									let all = pickables.current.filter((element: any) => {
										return element.name.includes(real);
									});

									all.forEach((element: any) => {
										highlightLayer.removeExcludedMesh(element);
										highlightLayer.addMesh(
											element,
											BABYLON.Color3.FromHexString("#7DF9FF")
										);
										// element.renderOutline = true;
									});

									highlightedAssetRef.current = all;
								} else {
									showPopover(res.pickedMesh.name, true);
									if (highlightedAssetRef?.current.length > 0) {
										removeHighlightedAsset();
									}
									highlightLayer.removeExcludedMesh(res.pickedMesh);
									highlightLayer.addMesh(
										res.pickedMesh,
										BABYLON.Color3.FromHexString("#7DF9FF")
									);
									// res.pickedMesh.renderOutline = true;

									highlightedAssetRef.current = [res.pickedMesh];
								}
							} else if (!closeMeshRef.current.includes(res.pickedMesh)) {
								showPopover(null, false);
								removeHighlightedAsset();
							}
						} else {
							showPopover(null, false);
							removeHighlightedAsset();
						}
					} else {
						showPopover(null, false);
						removeHighlightedAsset();
					}
				}

				document.onmousemove = (e) => {
					const popover: any = document.querySelector(".BabylonPopOver");
					if (popover && Pointerlock.current != true) {
						if (canvasRef.current.width / 2 < e.pageX) {
							popoverPlacementRef.current = "left";
							popover.style.left = `${e.pageX - 100}px`;
						} else {
							popoverPlacementRef.current = "right";
							popover.style.left = `${e.pageX - 50}px`;
						}

						if (canvasRef.current.height / 2 < e.pageY) {
							popover.style.top = `${e.pageY - 130}px`;
						} else {
							popover.style.top = `${e.pageY - 65}px`;
						}
					} else {
						popoverPlacementRef.current = "right";
						popover.style.left = `calc(50% + 10px)`;
						popover.style.top = `calc(50%)`;
					}
				};
			}, 1);
		}
	};

	const onSceneReady = (sceneComponent: any) => {
		setModelLoadDesc("Setting up Cameras");
		let scene: any = null;
		let canvas: any = null;
		let engine: any = null;
		if (sceneComponent.scene) {
			setScene(sceneComponent.scene);
			setCanvas(sceneComponent.canvas);

			scene = sceneComponent.scene;
			canvas = sceneComponent.canvas;
			engine = scene.getEngine();

			engineRef.current = engine;
			sceneRef.current = scene;
			canvasRef.current = canvas;
		} else {
			setScene(sceneRef.current);
			setCanvas(canvasRef.current);
			scene = sceneRef.current;

			scene.getEngine().resize();
		}

		scene.getEngine().resize();
	};

	var animateBox = function (series: any, value: any, speed: number) {
		let temp: any = kognifai_data_series_animation[series];

		if (temp) {
			var animationGroup: any = new BABYLON.AnimationGroup(series);
			temp.forEach((meshElement: any) => {
				let mesh: any = null;

				let YPos = meshElement.minY + value / meshElement.denominator;

				var meshAnimation: any = new BABYLON.Animation(
					`${meshElement.objectName}`,
					"position.y",
					speed,
					BABYLON.Animation.ANIMATIONTYPE_FLOAT,
					BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
				);
				var meshKeys: any = [];
				if (meshElement.objectType === "pickable") {
					mesh = pickables.current.filter((element: any) =>
						element.name.includes(meshElement.objectName)
					);
				} else if (meshElement.objectType === "animation") {
					mesh = animateMesh.current.filter((element: any) =>
						element.name.includes(meshElement.objectName)
					);
				}

				meshKeys.push({ frame: 0, value: mesh[0].position.y });
				meshKeys.push({ frame: 100, value: YPos });
				meshAnimation.setKeys(meshKeys);

				mesh.forEach((element: any) => {
					animationGroup.addTargetedAnimation(meshAnimation, element);
				});
			});
			animationGroup.start();
			animationGroupRef.current.push(animationGroup);
		}

		// let TopDrive: any = pickables.current.find(
		// 	(element: any) =>
		// 		element.name === "P.313001 TOP DRIVE FOR MAIN CENTRE (BUILD).nwd"
		// );
		// let PipesMesh: any = animateMesh.current.filter((element: any) =>
		// 	element.name.includes("A.312025")
		// );
		// let SheaveCluster: any = pickables.current.find((element: any) =>
		// 	element.name.includes("P.312026 SHEAVE CLUSTER")
		// );
		// let scene = sceneRef.current;
		// if (TopDrive && PipesMesh.length > 0 && SheaveCluster) {
		// 	let randomNumber = Math.random();

		// 	// +2 because min value is -2, so starting position is technically + 2
		// 	const randomTopY = Math.floor(49 + 2 + value);
		// 	const randomPipeY = Math.floor(73.1 + 2 / 2 + value / 2);
		// 	const randomSheaveY = Math.floor(81 + 2 / 2 + value / 2);

		// 	console.log(randomTopY, randomPipeY, randomSheaveY);

		// 	const TopY = Math.floor(49 + value);
		// 	const PipeY = Math.floor(73.1 + value / 2);
		// 	const SheaveY = Math.floor(81 + value / 2);

		// 	var animationGroup: any = new BABYLON.AnimationGroup("myAnimationGroup");

		// 	// Create animations for each mesh
		// 	var mesh1Animation: any = new BABYLON.Animation(
		// 		"mesh1Animation",
		// 		"position.y",
		// 		30,
		// 		BABYLON.Animation.ANIMATIONTYPE_FLOAT,
		// 		BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
		// 	);
		// 	var mesh2Animation: any = new BABYLON.Animation(
		// 		"mesh2Animation",
		// 		"position.y",
		// 		30,
		// 		BABYLON.Animation.ANIMATIONTYPE_FLOAT,
		// 		BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
		// 	);
		// 	var mesh3Animation: any = new BABYLON.Animation(
		// 		"mesh3Animation",
		// 		"position.y",
		// 		30,
		// 		BABYLON.Animation.ANIMATIONTYPE_FLOAT,
		// 		BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
		// 	);
		// 	var mesh1Keys: any = [];
		// 	mesh1Keys.push({ frame: 0, value: TopDrive.position.y });
		// 	mesh1Keys.push({ frame: 100, value: randomTopY });
		// 	mesh1Animation.setKeys(mesh1Keys);

		// 	var mesh2Keys: any = [];
		// 	mesh2Keys.push({ frame: 0, value: PipesMesh[0].position.y });
		// 	mesh2Keys.push({ frame: 100, value: randomPipeY });
		// 	mesh2Animation.setKeys(mesh2Keys);

		// 	var mesh3Keys: any = [];
		// 	mesh3Keys.push({ frame: 0, value: SheaveCluster.position.y });
		// 	mesh3Keys.push({ frame: 100, value: randomSheaveY });
		// 	mesh3Animation.setKeys(mesh3Keys);

		// 	// Add animations to the Animation Group
		// 	animationGroup.addTargetedAnimation(mesh1Animation, TopDrive);
		// 	PipesMesh.forEach((pipe: any) => {
		// 		animationGroup.addTargetedAnimation(mesh2Animation, pipe);
		// 	});
		// 	animationGroup.addTargetedAnimation(mesh3Animation, SheaveCluster);

		// 	animationGroup.start();
		// 	animationGroupRef.current.push(animationGroup);
		// }
	};

	// Download and render the whole Vessel Model
	const loadModel = (scene: any, hl: any) => {
		//console.log("loading model");
		var meshLevels = [];
		return new Promise((resolve: any, reject: any) => {
			if (scene) {
				BABYLON.SceneLoader.ImportMesh(
					"",
					`${url}/models/`,
					"logolessShip.glb",

					scene,
					(mesh: any) => {
						mesh[0].isVisible = !defaultInvisibleAsset;
						let meshes: any = {};
						let highPolyMesh: any = [];
						let _pickables: any = [];
						let animateMeshes: any = [];
						let AllMesh: any = [];

						for (var i = 1; i < mesh.length; i++) {
							mesh[i].isVisible = !defaultInvisibleAsset;
							const id = mesh[i].id;
							meshes[id] = mesh[i];

							// Set Pickable assets
							if (mesh[i].name.includes("P.")) {
								_pickables.push(mesh[i]);
								mesh[i].setParent(null);
								mesh[i].renderingGroupId = 1;
								// mesh[i].material.backFaceCulling = true;

								// Set animated assets
							} else if (mesh[i].name.includes("A.")) {
								animateMeshes.push(mesh[i]);
								mesh[i].renderingGroupId = 1;
								mesh[i].isPickable = false;
								// mesh[i].material.backFaceCulling = true;
								// animateMeshes.push(mesh[i]);
								// mesh[i].renderingGroupId = 1;
								// mesh[i].setParent(null);
								// mesh[i].material.backFaceCulling = true;

								// Set non-pickable assets
							} else {
								mesh[i].isPickable = false;
								mesh[i].doNotSyncBoundingInfo = true;
								mesh[i].visibility = 1;
								mesh[i].renderingGroupId = 2;
							}

							// Change pipe assets Level of Detail based on distance
							if (mesh[i].name.includes("-pipes")) {
								mesh[i].addLODLevel(100, null);
							}

							// Caculate poly count
							var vertexCount = mesh[i].getTotalVertices();
							var indexCount = mesh[i].getTotalIndices();
							var polygonCount = indexCount / 3;

							if (polygonCount > 1000000) {
								highPolyMesh.push(mesh[i]);
								//mesh[i].setEnabled(false);
							}

							// Create bounding box for hull
							if (mesh[i].name.includes("Hull_")) {
								// Assuming you have already created a Babylon.js scene and a mesh named "myMesh"

								// Calculate the current bounding box of the mesh
								var boundingBox = mesh[i].getBoundingInfo().boundingBox;

								// Define the amount by which you want to reduce the bounding box vertically
								var reductionAmount = -3; // Adjust this value as needed

								// Calculate the new dimensions of the 5bounding box
								var newMinimum = boundingBox.minimum.clone();
								var newMaximum = boundingBox.maximum.clone();
								//console.log(boundingBox);
								newMaximum.y -=
									(boundingBox.maximum.y - boundingBox.minimum.y) *
									reductionAmount;

								// Extend the maxZ and minX values
								newMaximum.z +=
									((boundingBox.maximum.z - boundingBox.minimum.z) *
										reductionAmount) /
									1.5;
								newMinimum.x -=
									((boundingBox.maximum.x - boundingBox.minimum.x) *
										reductionAmount) /
									2.5;

								// Extend the minZ and maxX values
								newMinimum.z -=
									((boundingBox.maximum.z - boundingBox.minimum.z) *
										reductionAmount) /
									1.5;
								newMaximum.x +=
									((boundingBox.maximum.x - boundingBox.minimum.x) *
										reductionAmount) /
									2.5;

								// Update the mesh's bounding box
								var customBoundingBox = new BABYLON.BoundingBox(
									newMinimum, // Minimum point
									newMaximum, // Maximum point
									boundingBox._worldMatrix
								);

								hullRef.current.push(customBoundingBox);
							}

							if (hl) hl.addExcludedMesh(mesh[i]);
							AllMesh.push(mesh[i]);

							// After finishing rendering all meshes
							if (i == mesh.length - 1) {
								// var planeOpts = {
								// 	height: 5.4762,
								// 	width: 7.3967,
								// 	sideOrientation: BABYLON.Mesh.DOUBLESIDE,
								// };
								// var ANote0Video = BABYLON.MeshBuilder.CreatePlane(
								// 	"plane",
								// 	planeOpts,
								// 	scene
								// );
								// var vidPos = new BABYLON.Vector3(0, 0, 0.1);
								// ANote0Video.position = vidPos;
								// var ANote0VideoMat = new BABYLON.StandardMaterial("m", scene);
								// var ANote0VideoVidTex = new BABYLON.VideoTexture(
								// 	"vidtex",
								// 	Video,
								// 	scene
								// );
								// ANote0VideoVidTex.video.muted = true;

								// ANote0VideoMat.diffuseTexture = ANote0VideoVidTex;
								// ANote0VideoMat.roughness = 1;
								// ANote0VideoMat.emissiveColor = BABYLON.Color3.White();
								// ANote0Video.material = ANote0VideoMat;

								// const music = new BABYLON.Sound("music", Audio, scene, null, {
								// 	loop: true,
								// 	autoplay: true,
								// 	spatialSound: true,
								// 	distanceModel: "exponential",
								// 	rolloffFactor: 2,
								// 	maxDistance: 20,
								// });
								// music.attachToMesh(ANote0Video);

								// AllMesh.push(ANote0Video);

								pickables.current = _pickables;
								animateMesh.current = animateMeshes;
								AllMeshes.current = AllMesh;
								highPolyMeshes.current = highPolyMesh;
								setHighlightLayer(hl);
								resolve(true);
							}
						}
					},
					(evt: any) => {
						var loadedPercent: any = 0;
						if (evt.lengthComputable) {
							loadedPercent = (evt.loaded * 100) / evt.total;
						} else {
							var dlCount = evt.loaded / (1024 * 1024);
							loadedPercent = Math.floor(dlCount * 100.0) / 100.0;
						}
						if (loadedPercent == 100) {
							setModelLoadDesc("Setting up the Digital Twin");
						}
						setModelLoaded((30 + (loadedPercent / 100) * 40).toFixed(2));
					}
				);
			}
		});
	};

	const addSkybox = (scene: any) => {
		return new Promise((resolve, reject) => {
			//V0.01
			// var skyEnv = new BABYLON.CubeTexture(TestEnv, scene);
			// skyEnv.rotationY = 9;
			// scene.environmentTexture = skyEnv;
			// scene.environmentIntensity = 2;
			// var skybox = BABYLON.Mesh.CreateBox("skyBox", 10000.0, scene);
			// skybox.position.y -= 150;
			// skybox.isPickable = false;
			// skybox.infiniteDistance = true;
			// var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
			// skyboxMaterial.backFaceCulling = false;
			// skyboxMaterial.disableLighting = true;
			// skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture(
			// 	"http://localhost:5000/api/models/skybox/d",
			// 	scene,
			// 	["_px.png", "_py.png", "_pz.png", "_nx.png", "_ny.png", "_nz.png"]
			// );
			// skyboxMaterial.reflectionTexture.coordinatesMode =
			// 	BABYLON.Texture.SKYBOX_MODE;
			// skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
			// skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
			// skybox.material = skyboxMaterial;
			// setSkyEnv(skyEnv);
			// LoadOcean(scene, skybox);
			// resolve(skybox);

			//V1
			// scene.clearColor = new BABYLON.Color3(1, 1, 1);
			var skyEnv = new BABYLON.CubeTexture(
				"https://dl.dropbox.com/s/nea7g9dywkpzkoc/sky.env",
				scene
			);
			skyEnv.rotationY = 2;
			scene.environmentTexture = skyEnv;
			scene.environmentIntensity = 5;
			var sphere = BABYLON.MeshBuilder.CreateSphere(
				"sphere",
				{ diameter: 5000, segments: 32 },
				scene
			);
			var sphereTexture = new BABYLON.Texture(Test, scene);
			sphereTexture.vScale = -1;
			var sphereMat = new BABYLON.StandardMaterial("sphereMat", scene);
			sphereMat.emissiveTexture = sphereTexture;
			sphereMat.backFaceCulling = false;
			sphere.material = sphereMat;
			sphere.rotation = new BABYLON.Vector3(0, 0.5, 0);
			sphere.position.y -= 40;
			sphere.isPickable = false;
			skyRef.current = sphere;
			resolve(sphere);

			//V2
			// const reflectionTexture = new BABYLON.HDRCubeTexture(
			// 	"https://www.babylonjs-playground.com/textures/environment.hdr",
			// 	scene,
			// 	128,
			// 	false,
			// 	true,
			// 	false,
			// 	true
			// );
			// var hdrTexture = new BABYLON.CubeTexture(
			// 	"https://www.babylonjs-playground.com/textures/environment.hdr",
			// 	scene
			// );
			// var envTexture = new BABYLON.CubeTexture(
			// 	"https://www.babylonjs-playground.com/textures/environment.env",
			// 	scene
			// );
			// scene.environmentTexture = envTexture;
			// scene.environmentIntensity = 2;
			// var skyboxMaterial = new BABYLON.PBRMaterial("skyboxMaterial", scene);
			// skyboxMaterial.reflectionTexture = reflectionTexture;
			// skyboxMaterial.emissiveColor = new BABYLON.Color3(0, 0, 1);
			// skyboxMaterial.backFaceCulling = false;
			// skyboxMaterial.microSurface = 1.0; // Adjust as needed
			// skyboxMaterial.cameraExposure = 1.0; // Adjust as needed
			// var skybox = BABYLON.MeshBuilder.CreateSphere(
			// 	"skybox",
			// 	{ diameter: 1000 },
			// 	scene
			// );
			// skybox.material = skyboxMaterial;
			// skybox.infiniteDistance = false;
			// LoadOcean(scene, skybox);
			// resolve(skybox);
		});
	};

	const LoadOcean = (scene: any, skybox: any) => {
		return new Promise(async (resolve, reject) => {
			//V0.01
			// var waterPlane: any = null;
			// waterPlane = BABYLON.MeshBuilder.CreateGround(
			// 	"ground",
			// 	{ width: 1048, height: 1048, subdivisions: 58 },
			// 	scene.scene
			// ); // ground.scaling = new BABYLON.Vector3(100, 100, 100);
			// waterPlane.rotation.y = Math.PI;
			// waterPlane.renderingGroupId = 2;
			// waterPlane.position.y -= -4.0;
			// waterPlane.position.x += 115;
			// waterPlane.isPickable = false;
			// var water = new BABYLONMAT.WaterMaterial(
			// 	"water",
			// 	scene,
			// 	new BABYLON.Vector2(512, 512)
			// );
			// water.backFaceCulling = true;
			// water.bumpTexture = new BABYLON.Texture(
			// 	"https://www.babylonjs-playground.com/textures/rockn.png",
			// 	scene
			// );
			// water.windForce = 5;
			// water.waveHeight = 0.4;
			// water.bumpHeight = 0.1;
			// water.waveSpeed = 0; // water.waveLength = 50;
			// water.windDirection = new BABYLON.Vector2(-1, 0);
			// water.waterColor = new BABYLON.Color3(0.1, 0.09, 0.22);
			// water.colorBlendFactor = 0.5;
			// water.addToRenderList(skybox);
			// // water.infiniteDistance = 100; // Adjust as needed
			// // water.tilingY = 100; // Adjust as needed
			// waterPlane.material = water;

			//V1
			var waterPlane: any = BABYLON.MeshBuilder.CreateGround(
				"waterplane",
				{ width: 5000, height: 5000 },
				scene
			);
			waterPlane.position.y = 9;
			var waterNormalMap = new BABYLON.Texture(
				"https://dl.dropbox.com/s/76obsake8lydcap/water_N.png",
				scene
			);
			waterNormalMap.vScale = -31;
			waterNormalMap.uScale = 30;
			// var waterOpacity = new BABYLON.Texture(a, scene);
			var waterOpacity = new BABYLON.Texture(
				"https://dl.dropbox.com/s/ztuvq7fnftkh8eh/waterOpacity.jpg",
				scene
			);

			var waterMat: any = await BABYLON.NodeMaterial.ParseFromFileAsync(
				"waterMat",
				"https://dl.dropbox.com/s/kr5lnxja8p63zlf/basicWater.json",
				scene
			);
			var waterNormal = waterMat.getBlockByName("waterNormal");
			waterNormal.texture = waterNormalMap;
			var opacityMap = waterMat.getBlockByName("opacityMap");
			opacityMap.texture = waterOpacity;
			waterPlane.material = waterMat;
			var waterNormalStrength = waterMat.getBlockByName("normalStrength");
			waterNormalStrength.value = 0.03;
			var waterSpeedX = waterMat.getBlockByName("SpeedX");
			waterSpeedX.value = 0.0014;
			var waterSpeedY = waterMat.getBlockByName("SpeedY");
			waterSpeedY.value = 0.0022;
			var waterSpeed2X = waterMat.getBlockByName("Speed2X");
			waterSpeed2X.value = -0.0016;
			var waterSpeed2Y = waterMat.getBlockByName("Speed2Y");
			waterSpeed2Y.value = -0.0062;
			var waterColor = waterMat.getBlockByName("baseColor");
			waterColor.value = BABYLON.Color3.FromHexString("#000000");
			var Roughness = waterMat.getBlockByName("Roughness");
			Roughness.value = 0.069;
			var metallic = waterMat.getBlockByName("metallic");
			metallic.value = 0.074;
			var normalBlend = waterMat.getBlockByName("NormalBlend");
			normalBlend.value = 0.5;

			waterPlane.renderingGroupId = 2;
			waterPlane.isPickable = false;

			setSeaState({
				strength: waterNormalStrength,
				speedX: waterSpeedX,
				speedY: waterSpeedY,
				speed2X: waterSpeed2X,
				speed2Y: waterSpeed2Y,
				metallic: metallic,
				roughness: Roughness,
				color: waterColor,
				blend: normalBlend,
				waterNormalMap: waterNormalMap,
			});

			oceanRef.current = waterPlane;
			// engineRef.current.hideLoadingUI();
			resolve(waterPlane);
		});
	};

	const LoadComponent = (
		scene: any,
		pickable: any,
		engineerKey: any = null
	) => {
		showPopover(null, false);
		downloadingRef.current = true;
		Inspector.Hide();
		setAssetLoaded(false);
		setModelLoadDesc("Downloading Asset");
		setModelLoaded(0);
		getEngineeringAsset(pickable).then((glb: any) => {
			// console.log(glb);
			BABYLON.SceneLoader.ImportMesh(
				"",
				`${url}/models/engineering/`,
				glb,
				scene,
				(mesh: any) => {
					// console.log(mesh);
					let components: any = [];
					mesh[0].isVisible = false;

					var boundingBox: any = new BABYLON.BoundingBox(
						BABYLON.Vector3.Zero(),
						BABYLON.Vector3.Zero()
					);

					for (var i = 1; i < mesh.length; i++) {
						var meshtest: any = mesh[i];
						meshtest.computeWorldMatrix(true);
						var boundingInfo: any = meshtest.getBoundingInfo();
						//	console.log(boundingInfo);
						boundingBox.reConstruct(
							boundingInfo.boundingBox.minimumWorld,
							boundingInfo.boundingBox.maximumWorld
						);

						components.push(mesh[i]);
						let assetnum = pickable.replace("P.", "").trim().split(/[-\s]/)[0];
						setSelectedKeys([assetnum]);
						setLeftDrawer(true);
						setMenuKey("Asset");
						if (mesh[i].name.includes("P.")) {
							const element_assetnum = mesh[i].name
								.replace("P.", "")
								.trim()
								.split(/[-\s]/)[0];
							mesh[i].isPickable =
								(element_assetnum.length > 6 &&
									assetnum.length < 7 &&
									!isNaN(parseInt(element_assetnum))) ||
								isNaN(parseInt(element_assetnum));
							mesh[i].renderingGroupId = 1;
						} else {
							mesh[i].isPickable = false;
							mesh[i].doNotSyncBoundingInfo = true;
							mesh[i].visibility = 1;
							mesh[i].renderingGroupId = 2;
						}

						highlightLayer.addExcludedMesh(mesh[i]);

						if (i == mesh.length - 1) {
							engineerMesh.current.push(...components);

							AllMeshes.current.map((element: any) => {
								element.setEnabled(false);
							});
							let target = scene.activeCamera.target.clone();
							let position = scene.activeCamera.position.clone();

							// if (!isFocusedRef.current) {
							previousPosition.current.target = target.clone();
							previousPosition.current.position = position.clone();
							// }

							// Component Arc Camera
							scene.activeCamera.detachControl();
							scene.activeCamera = cameraComRef.current;
							scene.activeCamera.position = BABYLON.Vector3.Zero();
							scene.activeCamera.radius = 30;
							// console.log(scene.activeCamera.radius);
							scene.activeCamera.attachControl(canvas, false);

							// if (fromtree) {
							// 	const boundingInfo = pickedmesh.getBoundingInfo();
							// 	const boundingBox = boundingInfo.boundingBox;
							// 	const min = boundingBox.minimum;
							// 	const max = boundingBox.maximum;

							// 	// Calculate the center position of the bounding box
							// 	const center = BABYLON.Vector3.Center(min, max);

							// 	// Calculate the distance from the center to the farthest point
							// 	const distance = BABYLON.Vector3.Distance(center, max);

							// 	// Calculate the frustum dimensions based on the distance

							// 	// Set the camera position and adjust target and rotation as needed
							// 	let position = new BABYLON.Vector3(
							// 		meshPosition.x + (distance > 500 ? 20 : distance / 50),
							// 		meshPosition.y,
							// 		meshPosition.z + (distance > 500 ? 20 : distance / 50)
							// 	);
							// 	scene.activeCamera.position = position.clone();

							// } else {
							// 	scene.activeCamera.position = position.clone();
							// }

							// scene.activeCamera.target = meshPosition.clone();
							// Calculate the center point of the bounding box
							var center = boundingBox.center;

							// Set the camera target to the center point
							scene.activeCamera.setTarget(center);

							// Optionally, you can also position the camera to get a better view
							var distance: any = BABYLON.Vector3.Distance(
								center,
								scene.activeCamera.position
							);
							scene.activeCamera.position = center.add(
								new BABYLON.Vector3(distance / 3, 0, distance / 3)
							);

							// scene.activeCamera.setTarget(mesh[i]);
							// scene.activeCamera.radius = 30;
							// console.log(scene.activeCamera.position);
							// console.log(scene.activeCamera.target);
							// console.log(mesh[i].position);

							//ArcCamera

							// scene.activeCamera.rebuildAnglesAndRadius();
							level2Ref.current = pickable;

							//ArcCamera
							Pointerlock.current = false;
							oceanRef.current.isVisible = false;
							skyRef.current.isVisible = false;
							isFocusedRef.current = true;
							setRightDrawer(true);
							setMaximoLoading(true);
							setMaximoView(true);
							setAssetLoaded(true);
							downloadingRef.current = false;
							handleSearch(assetnum + "_Asset");
							if (engineerKey) {
								getAssetData(engineerKey);
								setTempAssetKey(null);

								let mesh = components.find((element: any) => {
									return element.name.includes(engineerKey);
								});

								if (mesh) {
									engineerMesh.current.forEach((mesh: any) => {
										mesh.visibility = 0.03;
									});
									highlightedAssetRef.current = [];
									highlightLayer.removeMesh(mesh);
									highlightLayer.addMesh(
										mesh,
										BABYLON.Color3.FromHexString("#7DF9FF")
									);
									level3Ref.current = mesh;
									highlightLayer.removeExcludedMesh(mesh);
									mesh.visibility = 1;
								}
							} else {
								getAssetData(assetnum);
							}

							//ArcCamera
							document.exitPointerLock();
						}
					}
				},
				(evt: any) => {
					var loadedPercent: any = 0;
					if (evt.lengthComputable) {
						loadedPercent = (evt.loaded * 100) / evt.total;
					} else {
						var dlCount = evt.loaded / (1024 * 1024);
						loadedPercent = Math.floor(dlCount * 100.0) / 100.0;
					}
					if (loadedPercent == 100) {
						setModelLoadDesc("Finish Download");
					}
					setModelLoaded((30 + (loadedPercent / 100) * 40).toFixed(2));
				}
			);
		});
	};

	const animateCameraTo = (target: any) => {
		if (cameraRef) {
			const camera: any = cameraRef.current;
			const positionAnimation = new BABYLON.Animation(
				"positionAnimation",
				"position",
				30,
				BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
				BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
			);
			const targetAnimation = new BABYLON.Animation(
				"targetAnimation",
				"target",
				30,
				BABYLON.Animation.ANIMATIONTYPE_VECTOR3,
				BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
			);
			const fovAnimation = new BABYLON.Animation(
				"fovAnimation",
				"fov",
				30,
				BABYLON.Animation.ANIMATIONTYPE_FLOAT,
				BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
			);
			positionAnimation.setKeys([
				{ frame: 0, value: camera.position },
				{ frame: 30, value: target.position },
			]);
			fovAnimation.setKeys([
				{ frame: 0, value: camera.fov },
				{ frame: 30, value: target.fov },
			]);
			targetAnimation.setKeys([
				{ frame: 0, value: camera.target },
				{ frame: 30, value: target.target },
			]);
			camera.animations = [positionAnimation, targetAnimation, fovAnimation];
			camera.getScene().beginAnimation(camera, 0, 30, false, 1, () => {
				//console.log("ANIMATION DONE");
			});
		}
	};

	const fitCameraToSelectedMeshes = (camera: any, meshes: any) => {
		//console.log("Did this happened?");
		try {
			const visibleMeshes: any = meshRefs.current.filter((mesh: any) =>
				mesh.current?.isEnabled()
			);
			const allMeshes: any = BABYLON.Mesh.MergeMeshes(
				meshes.map((mesh: any) => mesh.current),
				false,
				true
			);
			const boundingInfo = allMeshes.getBoundingInfo();
			const radius = boundingInfo.boundingSphere.radiusWorld;
			const center = boundingInfo.boundingSphere.centerWorld;

			const direction = camera.position.subtract(center).normalize();
			const distance = radius / Math.sin(camera.fov / 2);
			const newPosition = center.subtract(direction.scale(distance));

			allMeshes.dispose();

			const newTarget: any = {
				position: newPosition,
				target: center,
				fov: camera.fov,
			};

			animateCameraTo(newTarget);
		} catch (e: any) {
			//console.log("error", e);
		}
	};

	const viewData = (
		data: any,
		bordered: any = true,
		type: any = "horizontal"
	) => {
		return (
			<Descriptions bordered={bordered} layout={type}>
				<>
					{Object.keys(data).map((element: any) => {
						return (
							<Descriptions.Item
								span={3}
								label={element[0].toUpperCase() + element.slice(1)}
							>
								{data[element]}
							</Descriptions.Item>
						);
					})}
				</>
			</Descriptions>
		);
	};

	const setMaximoContent = (tabKey: any) => {
		switch (tabKey) {
			case "overview":
				return (
					<div
						style={{
							display: "flex",
							padding: "16px",
							alignItems: "flex-start",
							gap: "24px",
							flex: 1,
							alignSelf: "stretch",
						}}
					>
						<div
							style={{
								width: "60%",
								fontSize: "14px",
								fontWeight: "600",
								lineHeight: "22px",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								gap: "8px",
								alignSelf: "strech",
								color: "rgba(255,255,255,0.85)",
							}}
						>
							<div className="digital-twin-maximo-overview-content-container">
								<span>Asset Details</span>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Description</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Number</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Parent</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Children</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
							</div>
							<Divider></Divider>
							<div className="digital-twin-maximo-overview-content-container">
								<span>Criticality</span>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Safety</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Environment</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Production</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Equipment</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
							</div>
							<Divider></Divider>
							<div className="digital-twin-maximo-overview-content-container">
								<span>Maintenance</span>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Next Due Date</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Days remaining</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Last Associated Maintenance</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
								<div className="digital-twin-maximo-overview-content-data">
									<span>Equipment</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
							</div>
						</div>
						<Divider style={{ height: "100%" }} type={"vertical"}></Divider>
						<div
							style={{
								flex: 1,
								fontSize: "14px",
								fontWeight: "600",
								lineHeight: "22px",
							}}
						>
							<div className="digital-twin-maximo-overview-content-container">
								<span>EFR</span>
								<div
									className="digital-twin-maximo-overview-content-data"
									style={{ flexDirection: "column" }}
								>
									<span>Last Failure</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
								<div
									className="digital-twin-maximo-overview-content-data"
									style={{ flexDirection: "column" }}
								>
									<span>Total Failures</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
							</div>
							<Divider />
							<div className="digital-twin-maximo-overview-content-container">
								<div
									className="digital-twin-maximo-overview-content-data"
									style={{ flexDirection: "column" }}
								>
									<span>Total Downtime</span>
									<span className="digital-twin-maximo-value">-</span>
								</div>
							</div>
						</div>
					</div>
				);
		}
	};

	const setRightDrawerContent = () => {
		if (isMaximoView) {
			return (
				<Spin
					className="maximo-antd-spin"
					wrapperClassName="maximo-loading-wrapper"
					spinning={isMaximoLoading}
					tip={"Fetching Maximo data"}
				>
					{setMaximoContent(rightDrawerTabKey)}
				</Spin>
			);
		} else {
			switch (rightDrawerKey) {
				case "Asset":
					return (
						<Spin
							className="maximo-antd-spin"
							wrapperClassName="maximo-loading-wrapper"
							spinning={isMaximoLoading}
							tip={"Fetching Maximo data"}
						>
							{isMaximoLoading == false && (
								<>
									{maximoData !== null ? (
										<>{viewData(maximoData)}</>
									) : (
										<div>NO DATA</div>
									)}
								</>
							)}
						</Spin>
					);
				case "Crew":
					let data = rightDrawerData;
					return (
						<div
							className="crew-detail-generic-font"
							style={{ height: "100%" }}
						>
							<Space size={16} direction="vertical" style={{ width: "100%" }}>
								<Space
									direction="horizontal"
									style={{
										width: "100%",
										alignItems: "normal",
										justifyContent: "space-between",
									}}
								>
									<Space
										size={0}
										direction="vertical"
										style={{
											width: "100%",
										}}
									>
										<span className="crew-detail-generic-title">
											Occupation
										</span>
										{data.occupation}
										<span></span>
									</Space>
									{data.occupation?.includes("(Temp)") ? (
										<span className="crew-detail-generic-title">Temporary</span>
									) : null}
								</Space>
								<Space size={0} direction="vertical">
									<span className="crew-detail-generic-title">Area</span>
									<span>{data.area}</span>
								</Space>
							</Space>
							<Divider className="small-divider" />
							<div>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">Name</span>
									<span>{data.full_name}</span>
								</Space>
							</div>
							<Divider className="small-divider" />
							<Space direction="vertical" size={16} style={{ width: "100%" }}>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Arrival Date
									</span>
									<span>{data.arrival_date}</span>
								</Space>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Departure Date
									</span>
									<span>{data.departure_date}</span>
								</Space>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Days onboard
									</span>
									<span>{Math.trunc(data.days_on_rig).toString()}</span>
								</Space>
							</Space>
							<Divider className="small-divider" />
							<Space
								direction="horizontal"
								style={{ width: "100%" }}
								styles={{ item: { flex: "1" } }}
							>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Cabin / Bunk
									</span>
									<span>{data.cabin}</span>
								</Space>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">Life Boat</span>
									<span>{data.lifeboat_name}</span>
								</Space>
							</Space>
							<Divider className="small-divider" />
							<Space direction="vertical" style={{ width: "100%" }} size={16}>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Company Contact
									</span>
									<span>{data.company_contact_no}</span>
								</Space>
								<Space direction="vertical" size={0}>
									<span className="crew-detail-generic-title">
										Emergency Team
									</span>
									<span>{data.emergency_team}</span>
								</Space>
								{data.email != "-" ? (
									<Button
										icon={GetAntIcon("mail")}
										onClick={() => {
											window.open(`mailto:${data.email}`);
										}}
										title="Send Email"
									>
										Send Email
									</Button>
								) : (
									<></>
								)}
							</Space>
						</div>
					);
			}
		}
	};

	const setRightDrawerTitle = () => {
		if (isMaximoView) {
			return "Maximo Data";
		} else {
			switch (rightDrawerKey) {
				case "Asset":
					return "Maximo Data";
				case "Crew":
					return "Crew Details";
			}
		}
	};

	const setTutorialContent = () => {
		switch (tutorial) {
			case 1:
				return (
					<div style={{ paddingTop: "24px" }}>
						<div style={{ display: "grid", gap: "36px" }}>
							<div
								style={{
									fontWeight: "400",
									fontSize: "30px",
									lineHeight: "40px",
								}}
							>
								Digital Twin
							</div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "22px",
									lineHeight: "1.25",
								}}
							>
								Welcome! To maximise your experience navigating Digital Twin, we
								have prepared a quick-start guide to get you up and running
							</div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "16px",
									lineHeight: "1.25",
								}}
							>
								Please note that the Digital Twin service enables you to access
								data from various isolated systems in the context of real-time
								operations. It is important to understand that you are not
								granted the ability to control the vessel or any of the
								connected systems from within this service
							</div>
						</div>
						<Divider />
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Checkbox
								checked={noShow}
								onChange={(e: any) => {
									setNoShow(e.target.checked);
								}}
							>
								Do not show this again
							</Checkbox>
							<Space style={{ marginLeft: "auto" }}>
								{/* <Button
									onClick={() => {
										setTutorialModal(false);
										if (noShow) {
											localStorage.setItem("skipTutorial", "true");
										}
									}}
								>
									Skip
								</Button> */}
								<Button
									onClick={() => {
										// setTutorial(2);
										setTutorialModal(false);
										menuKeyRef.current = "Help";
										setMenuKey("Help");
										setLeftDrawer(true);

										if (noShow) {
											localStorage.setItem("skipTutorial", "true");
										}
									}}
								>
									Continue
								</Button>
							</Space>
						</div>
					</div>
				);
			case 2:
				return (
					<div>
						<div
							style={{
								fontWeight: "400",
								fontSize: "30px",
							}}
						>
							Navigating the 3D Scene
						</div>

						<Divider />
						<Space style={{ display: "flex", justifyContent: "flex-end" }}>
							{/* <Button
								onClick={() => {
									setTutorialModal(false);
								}}
							>
								Skip
							</Button> */}
							<Button
								onClick={() => {
									// setTutorial(3);
									// setMenuKey(null);
									// setLeftModal(false);
									// setTimeout(() => {
									// 	setMenuKey("Asset");
									// 	setLeftModal(true);
									// }, 1);
									setTutorialModal(false);
									canvasRef.current.focus();
								}}
							>
								Finish
							</Button>
						</Space>
					</div>
				);
			case 3:
				return (
					<>
						<div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "30px",
								}}
							>
								Discover the Vessel Assets and Properties
							</div>

							<Divider />
							<Space style={{ display: "flex", justifyContent: "flex-end" }}>
								<Button
									onClick={() => {
										setTutorialModal(false);
									}}
								>
									Skip
								</Button>
								<Button
									onClick={() => {
										setTutorial(4);
										setMenuKey(null);
										menuKeyRef.current = "";
										setLeftDrawer(false);
									}}
								>
									Continue
								</Button>
							</Space>
						</div>
						<div style={{ position: "fixed", top: 0, left: 0 }}>
							<div style={{ position: "fixed", top: "300px", left: "500px" }}>
								{navigateSVG()}
							</div>
							<div
								style={{
									position: "fixed",
									top: "70px",
									left: "50%",
									transform: "translateX(-415px)",
								}}
							>
								{searchVesselSVG()}
							</div>
							<div style={{ position: "fixed", top: "170px", left: "20px" }}>
								{accessVesselSVG()}
							</div>
							{/* <div>{discoverVesselPropertiesSVG()}</div> */}
						</div>
					</>
				);
			case 4:
				return (
					<div style={{ paddingTop: "24px" }}>
						<div style={{ display: "grid", gap: "36px" }}>
							<div
								style={{
									fontWeight: "400",
									fontSize: "30px",
									lineHeight: "40px",
								}}
							>
								Tutorial Completed!
							</div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "16px",
									lineHeight: "22px",
								}}
							>
								Congratulations! You have successfully completed the tutorial
								and are now prepared to fully utilize the Digital Twin
							</div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "16px",
									lineHeight: "22px",
								}}
							>
								If you ever need a refresher, you can always access the tutorial
								from the "Actions" menu in the upper right corner. Happy
								exploring!
							</div>
						</div>
						<Divider />
						<Space style={{ display: "flex", justifyContent: "flex-end" }}>
							<Button
								onClick={() => {
									setTutorialModal(false);
								}}
							>
								Got it!
							</Button>
						</Space>
					</div>
				);
			default:
				return <></>;
		}
	};

	//MAIN
	return (
		<div
			style={{
				position: "relative",
				width: "100%",
				height: "100%",
				overflow: "hidden",
			}}
		>
			{acceptedroles && (
				<Spin
					// style={{ color: "rgb(77, 77, 77)" }}
					size="large"
					tip={
						<div
							style={{
								color: "white",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<span style={{ fontSize: "30px" }}>{ModelLoadDesc}</span>
							<Progress style={{ width: "50%" }} percent={ModelLoadPercent} />
						</div>
					}
					indicator={<img src={Portal_Icon_Logo} />}
					className={"antd-spin-blink"}
					wrapperClassName={"dt2-loading-wrapper"}
					spinning={!(isLoaded && isAssetLoaded)}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							minHeight: "50px",
							width: "100vw",
							background: "#031220",
						}}
					>
						<Select
							ref={searchInputRef}
							showSearch
							allowClear
							showArrow={false}
							// filterOption={false}
							defaultActiveFirstOption={true}
							id="asset-search"
							placeholder="Search for asset name or id..."
							className="document-search"
							optionFilterProp="label"
							style={{
								left: "20%",
								position: "fixed",
								width: "50%",
								maxWidth: "500px",
							}}
							value={selectValue}
							onChange={(value: any) => {
								setSelectValue(value);
								if (value) {
									setSearchResults([]);
									setIsSearch(false);
									canvasRef.current.focus();
									setMenuKey("Asset");
									setLeftDrawer(true);
									setSelectedKeys(value ? [value] : []);
								}
							}}
							options={selectData}
						></Select>
						<Space
							style={{
								left: "70%",
								position: "fixed",
							}}
						>
							<Select
								style={{ width: "150px" }}
								className="document-search"
								defaultValue={true}
								disabled={isFocusedRef.current}
								options={[
									{ label: "Environment On", value: true },
									{ label: "Environment Off", value: false },
								]}
								onChange={(value: any) => {
									skyRef.current.setEnabled(value);
									oceanRef.current.setEnabled(value);
									canvasRef.current.focus();
								}}
							></Select>
							<Select
								style={{ width: "170px" }}
								className="document-search"
								defaultValue={"1"}
								value={cameraMode}
								disabled={level2Ref.current}
								options={[
									{ label: "PoV Mode", value: "1" },
									{ label: "Presentation Mode", value: "2" },
									{ label: "Clipping Mode", value: "3" },
								]}
								onChange={(value: any) => {
									setCameraMode(value);
									changeCamera(value);
									canvasRef.current.focus();
								}}
							></Select>
							<Select
								style={{ width: "100px" }}
								className="document-search"
								defaultValue={graphicalEngine}
								options={[
									{ label: "WebGL", value: "WebGL" },
									{ label: "WebGPU", value: "WebGPU" },
								]}
								onChange={(value: any) => {
									localStorage.setItem("graphicsEngine", value);
									setGraphicEngine(value);
								}}
							></Select>
						</Space>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							// flex: 1,
							height: "calc(100% - 50px)",
							width: "100%",
							// position: "relative",
						}}
					>
						{" "}
						<Menu
							className="dt-side-menu"
							rootClassName="dt-root-side-menu"
							mode="inline"
							theme="dark"
							onClick={(e: any) => {
								if (
									rightDrawerKeyRef.current === e.key ||
									["Help", "Settings"].includes(e.key)
								) {
								} else {
									setRightDrawer(false);
									setSelectedKeys([]);
									if (engineerMesh.current.length > 0) {
										engineerMesh.current.map((element: any) => {
											highlightLayer.removeExcludedMesh(element);
											highlightLayer.removeMesh(element);
											element.dispose();
										});
										engineerMesh.current = [];
									}
									setMaximoData({});
									AllMeshes.current.map((element: any) => {
										element.setEnabled(true);
									});

									if (level2Ref.current || level3Ref.current) {
										console.log(previousPosition.current);
										scene.activeCamera.detachControl();

										scene.activeCamera = activeCameraRef.current;
										scene.activeCamera.attachControl(canvas, false);

										scene.activeCamera.position =
											previousPosition?.current?.position;
										scene.activeCamera.target =
											previousPosition?.current?.target;
									}

									level3Ref.current = null;
									level2Ref.current = null;
									level1Ref.current = null;
									setMaximoData({});
									isFocusedRef.current = false;
									oceanRef.current.isVisible = true;
									skyRef.current.isVisible = true;
								}
								if (e.key !== menuKey) {
									if (isSearch) {
										setTimeout(() => {
											setIsSearch(false);
											setSearchResults([]);
											setSearchValue("");
											setExpandedKeys([]);
										}, 100);
									}

									if (e.key != "Inspector") {
										mqttRef.current.key = e.key;
										menuKeyRef.current = e.key;
										Inspector.Hide();

										isInspectorOpenRef.current = false;
										if (leftDrawer) {
											setLeftDrawer(false);
											setTimeout(() => {
												setMenuKey(e.key);
												setLeftDrawer(true);
											}, 100);
										} else {
											setMenuKey(e.key);
											setLeftDrawer(true);
										}
									} else {
										setLeftDrawer(false);
										setMenuKey(e.key);
										Inspector.Show(sceneRef.current, {
											embedMode: true,
											enableClose: true,
											enablePopup: false,
										});
										isInspectorOpenRef.current = true;
									}
								} else {
									setLeftDrawer(false);
									Inspector.Hide();
									isInspectorOpenRef.current = false;
									setTimeout(() => {
										menuKeyRef.current = "";
										setMenuKey(null);
										setIsSearch(false);
										setSearchResults([]);
										setSearchValue("");
										setExpandedKeys([]);
									}, 100);
								}
								canvasRef.current.focus();
							}}
							selectedKeys={menuKey}
							items={[
								{
									label: "Asset",
									key: "Asset",
									icon: GetAntIcon("expand"),
								},
								{ label: "Crew", key: "Crew", icon: GetAntIcon("team") },
								{
									label: "Emissions",
									key: "Emission",
									icon: GetAntIcon("sync"),
								},
								{
									label: "MQTT",
									key: "MQTT",
									icon: GetAntIcon("cloud"),
								},
								{
									label: "Inspector",
									key: "Inspector",
									icon: GetAntIcon("tool"),
									style: { marginTop: "auto" },
								},
								// {
								// 	label: "Settings",
								// 	key: "Settings",
								// 	icon: GetAntIcon("setting"),
								// },

								{
									label: "Help",
									key: "Help",
									icon: GetAntIcon("question3"),
									// style: { marginTop: "auto" },
								},
							]}
						/>
						<div
							style={{
								width: "100%",
								height: "100%",
								position: "relative",
								overflow: "hidden",
							}}
						>
							{graphicalEngine === "WebGPU" ? (
								<canvas
									ref={canvasRef}
									style={{ width: "100%", height: "100%", display: "block" }}
								/>
							) : (
								<Engine antialias adaptToDeviceRatio canvasId="DigitalTwin">
									<Scene onSceneMount={onSceneReady}>
										<universalCamera
											name="UniCamera"
											ref={cameraRef}
											target={defaultTarget}
											position={cloned}
											//Lowering Inertia can make the camera to jump a few meters
											inertia={0}
											speed={cameraSpeed}
											angularSensibility={1000}
											minZ={0.3}
											fov={cameraFov}
										/>
										<arcRotateCamera
											name="RotateCamera"
											useAutoRotationBehavior={true}
											ref={cameraArcRef}
											target={new BABYLON.Vector3(98.92, 46.94, 282.67)}
											alpha={Math.PI / 4}
											beta={Math.PI / 4}
											radius={10}
											upperRadiusLimit={300}
											lowerRadiusLimit={300}
											minZ={0.5}
											fov={0.5}
										/>
										<arcRotateCamera
											name="RotateComponentCamera"
											ref={cameraComRef}
											target={new BABYLON.Vector3(0, 0, 0)}
											alpha={Math.PI / 4}
											beta={Math.PI / 4}
											radius={10}
											upperRadiusLimit={300}
											lowerRadiusLimit={1}
											minZ={0.1}
										/>
									</Scene>
								</Engine>
							)}
							<>{DrawerMemo}</>
							<Drawer
								open={bottom_modal}
								placement="bottom"
								height={500}
								className={"digitaltwin-drawer-wrapper"}
								onClose={() => setBottomModal(false)}
								getContainer={false}
								mask={false}
								maskClosable={false}
								destroyOnClose={true}
								closable={false}
								title={"Data Layer"}
								style={{ overflow: "hidden" }}
							/>

							<Drawer
								open={rightDrawer}
								width={640}
								placement="right"
								className={`digitaltwin-drawer-wrapper`}
								getContainer={false}
								mask={false}
								maskClosable={false}
								destroyOnClose={false}
								closable={false}
								title={
									// <div
									// 	style={{
									// 		width: "100%",
									// 		display: "flex",
									// 		justifyContent: "space-between",
									// 		alignItems: "center",
									// 	}}
									// >
									// 	<span style={{ fontSize: "14px" }}>
									// 		{setRightDrawerTitle()}
									// 	</span>
									// 	<Button
									// 		// style={{ color: "#FFFFFFD9" }}
									// 		type="text"
									// 		ghost
									// 		onClick={() => {
									// 			setRightDrawer(false);
									// 			setRightDrawerData(null);
									// 			setRightDrawerKey("");
									// 			setMaximoView(false);
									// 			rightDrawerKeyRef.current = "";
									// 			setSelectedKeys([]);
									// 		}}
									// 	>
									// 		{GetAntIcon("close")}
									// 	</Button>
									// </div>
									<div style={{ width: "100%" }}>
										<Tabs
											// style={{ margin: "0" }}
											activeKey={rightDrawerTabKey}
											onChange={(key: any) => {
												setRightDrawerTabKey(key);
											}}
											items={[
												{
													label: "Overview",
													key: "overview",
													disabled: isMaximoLoading,
												},
												{
													label: "Maintenance",
													key: "maintenance",
													disabled: isMaximoLoading,
												},
												{
													label: "Equipment failures",
													key: "equipment-failures",
													disabled: true,
												},
												{
													label: "Modifications",
													key: "modifications",
													disabled: true,
												},
											]}
										></Tabs>
									</div>
								}
								style={{ position: "absolute" }}
								styles={{
									body: { paddingTop: "0px" },
									header: {
										padding: "0px 16px",
										fontSize: "14px",
										borderBottom: "0px",
									},
								}}
								onClose={() => {
									setRightDrawerTabKey("overview");
								}}
								afterOpenChange={() => canvasRef.current.focus()}
							>
								{
									<div style={{ color: "white", height: "100%" }}>
										{setRightDrawerContent()}
									</div>
								}
							</Drawer>
							<Modal
								// destroyOnClose
								width={800}
								onCancel={() => {
									setTutorialModal(false);
								}}
								style={
									tutorial == 2 || tutorial == 3
										? { opacity: "0.90", left: "300px" }
										: { opacity: "0.90" }
								}
								maskClosable={false}
								open={tutorialModal}
								centered
								closable={false}
								footer={null}
							>
								{setTutorialContent()}
							</Modal>
							{/* <Modal
							// destroyOnClose
							width={800}
							onCancel={() => {
								console.log("is this close?");
								setSelectedKeys([]);
								setFSModal(false);
								canvasRef.current.focus();
								setMaximoData({});
								getAssetData(currentMaximoKey);
								setRightDrawer(true);
								setMaximoLoading(true);
								setMaximoView(true);
							}}
							onOk={() => {
								setFSModal(false);
								setSelectedKeys([]);
								ContextConsumer.handleUSModal({
									component: "Digital Twin",
									message: FSMessage,
								});
							}}
							maskClosable={true}
							open={FSModal}
							centered
							closable={false}
						>
							<div
								style={{
									fontWeight: "400",
									fontSize: "30px",
									lineHeight: "40px",
								}}
							>
								Missing 3D Asset
							</div>
							<Divider></Divider>
							<div
								style={{
									fontWeight: "400",
									fontSize: "22px",
									lineHeight: "1.25",
									marginBottom: "50px",
								}}
							>
								The 3D asset pertaining to <b>{FSMessage}</b> is presently not
								available. Nevertheless, you can still retrieve the related
								asset data using the informations panels.
								<br></br>
								<br />
								If you come across any missing assets, you can{" "}
								<b>
									submit a request to add assets by clicking on the button
									below.
								</b>{" "}
								These requests will be added to our backlog and given prompt
								attention.
							</div>
							<div
								style={{
									fontWeight: "400",
									fontSize: "16px",
									lineHeight: "1.25",
								}}
							></div>
						</Modal> */}
							<Modal
								width={425}
								maskClosable={true}
								open={FSModal}
								closable={false}
								style={{
									position: "fixed",
									left: "380px",
									top: "calc(50% - 260px/2)",
								}}
								onCancel={() => {
									setSelectedKeys([]);
									setFSModal(false);
									canvasRef.current.focus();
									setMaximoData({});
									getAssetData(currentMaximoKey);
									setRightDrawer(true);
									setMaximoLoading(true);
									setMaximoView(true);
								}}
								footer={null}
							>
								<Space direction="horizontal" align="start">
									<div
										style={{
											color: "#177DDC",
											fontSize: "24px",
											width: "30px",
											marginTop: "-7px",
										}}
									>
										{GetAntIcon("exclamation")}
									</div>

									<Space direction="vertical" size={8}>
										<div
											style={{
												fontWeight: "500",
												fontSize: "16px",
												lineHeight: "24px",
											}}
										>
											Asset Missing
										</div>
										<div
											style={{
												fontWeight: "400",
												fontSize: "14px",
												lineHeight: "22px",
												marginBottom: "30px",
												color: "#FFFFFFA6",
											}}
										>
											This asset is currently unavailable.
											<br></br>
											<br />
											If you'd like to add it, please submit a request to the
											development team by clicking the 'Request Asset' button
											below. We will get back to you shortly.
										</div>
										<Space
											style={{ justifyContent: "end", display: "flex" }}
											direction="horizontal"
										>
											<Button
												onClick={() => {
													setSelectedKeys([]);
													setFSModal(false);
													canvasRef.current.focus();
													setMaximoData({});
													getAssetData(currentMaximoKey);
													setRightDrawer(true);
													setMaximoLoading(true);
													setMaximoView(true);
												}}
											>
												Close
											</Button>
											<Button
												type="primary"
												onClick={() => {
													setFSModal(false);
													setSelectedKeys([]);
												}}
											>
												Request asset
											</Button>
										</Space>
									</Space>
								</Space>
							</Modal>
							<Popover
								placement={popoverPlacementRef.current}
								className="BabylonPopOver"
								open={popoverVisible && isAssetLoaded}
								arrow={false}
								content={popoverContent}
							/>
						</div>
					</div>
				</Spin>
			)}
			{acceptedroles === false && <NoAccess text={"Unable to load"} />}
		</div>
	);
};

const getFormattedTitle = (
	type: string,
	level: number,
	node: any,
	exist: boolean,
	ellipsis: boolean = true
) => {
	const setIcon = (key: any, level: any) => {
		switch (key) {
			case "Asset":
				switch (level) {
					case 1:
						return (
							<span style={{ fontSize: "12px" }}>{GetAntIcon("expand")}</span>
						);

					case 2:
						return (
							<span style={{ fontSize: "10px" }}>{GetAntIcon("compress")}</span>
						);

					case 3:
						return (
							<span style={{ fontSize: "10px" }}>{GetAntIcon("dash")}</span>
						);
					case 4:
						return (
							<span style={{ fontSize: "10px" }}>
								{GetAntIcon("small-dash")}
							</span>
						);
				}
				break;
			case "Crew":
				switch (level) {
					case 1:
						return (
							<span style={{ fontSize: "12px" }}>{GetAntIcon("group")}</span>
						);

					case 2:
						return (
							<span style={{ fontSize: "10px" }}>{GetAntIcon("ungroup")}</span>
						);

					case 3:
						return (
							<span style={{ fontSize: "10px" }}>{GetAntIcon("user")}</span>
						);
				}
				break;
		}
	};
	const { cleanedTitle = "", key = "" } = node;
	return (
		<Space id={key} direction="horizontal">
			<div>{setIcon(type, level)}</div>
			<Space
				direction="vertical"
				title=""
				style={exist ? {} : { opacity: "0.3" }}
				styles={{ item: { marginBottom: "-3px" } }}
				size={0}
			>
				{type == "Asset" && (
					<Text
						className="digitaltwin-asset-tree-number"
						style={{ fontSize: 12, color: "#FFFFFF73" }}
					>
						{key}
					</Text>
				)}
				<Text
					title=""
					ellipsis={{
						onEllipsis: () => {},
						tooltip: <span>{cleanedTitle}</span>,
					}}
					style={
						type === "Crew" && node.full_name === "-"
							? ellipsis
								? {
										color: "#FF2D2D",
										width: `calc(280px - ${Number(level > 1) * 10}px)`,
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
										overflow: "hidden",
										display: "inherit",
								  }
								: {
										color: "#FF2D2D",
										display: "inherit",
										width: `calc(280px - ${Number(level > 1) * 10}px)`,
										maxWidth: "280px",
								  }
							: ellipsis
							? {
									color: "#FFFFFFA6",
									width: `calc(280px - ${Number(level > 1) * 20}px)`,
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
									overflow: "hidden",
									display: "inherit",
							  }
							: {
									color: "#FFFFFFA6",
									display: "inherit",
									width: `calc(280px - ${Number(level > 1) * 10}px)`,
									maxWidth: "280px",
							  }
					}
				>
					{cleanedTitle}
					<span>
						{node.childrenCount > 0 && type === "Crew"
							? `(${node.childrenCount})`
							: null}
					</span>
				</Text>
			</Space>
		</Space>
	);
};

const findAsset = (node: any, availableAssets: any, level: number) => {
	const { key = "" } = node;
	if (level === 1) {
		return availableAssets.level1.find(
			(assetNumber: any) => assetNumber === key
		);
	} else if (level >= 2) {
		return availableAssets.level2.find(
			(assetNumber: any) => assetNumber === key
		);
	} else {
		return false;
	}
};

export default DigitalTwin;
