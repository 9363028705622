/**
 * Main source of the client react system
 * 	- Stores & Create routes & pages
 * 	- Auto navigate logic
 *  - Check logins & tokens
 *  -
 */

import React, { useEffect, useMemo, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
} from "react-router-dom";
import { Alert, ConfigProvider, theme } from "antd";
import Location from "./utils/location";
import jwtDecode from "jwt-decode";
import Emitter from "./utils/emitter";
import { socket } from "./utils/socket";

import NoAccess from "./containers/NoAccess";
import { superAdminRole } from "./utils/_exports";
import { GetAntIcon } from "./utils/ant_icons";
import DigitalTwin from "./containers/DigitalTwin";
import { getAlertStyle } from "./utils/utils";
export const Mode = `${process.env.REACT_APP_MODE}`;
export let Tenant = `elemental`;

const { defaultAlgorithm, darkAlgorithm } = theme;

if (localStorage.getItem("theme")) {
	const theme = localStorage.getItem("theme");
	if (theme == "dark") {
		document.body.classList.toggle("dark-mode", true);
	} else if (theme == "light") {
		document.body.classList.toggle("dark-mode", false);
	}
} else {
	localStorage.setItem("theme", "dark");
	document.body.classList.toggle("dark-mode", true);
}

let tokenCheck: any = null;
const restricted = ["/", "loginsuccess", "logout"];

const App = () => {
	let isLogin = false;
	let shouldRedirect = false;
	let currentDate: any = Date.now() / 1000;
	let timeout: any = null;

	const restricted = [
		"",
		"/",
		"/loginsuccess",
		"/loginsuccess/",
		"/logout",
		"/logout/",
	];

	const [user, setUser] = useState<any>(null);
	const [userRole, setUserRole] = useState<any>([]);
	const [menu, setMenu] = useState<[]>([]);
	const [menuRoutes, setMenuRoutes] = useState<[]>([]);
	const [adminMenuRoutes, setAdminMenuRoutes] = useState<[]>([]);
	const [currentLocation, setCurrentLocation] = useState<any>(
		window.location.pathname
	);
	const [accessTokenDecoded, setAccessTokenDecoded] = useState<any>(null);
	const [idTokenDecoded, setIDTokenDecoded] = useState<any>(null);

	const [alert, setAlert] = useState<any>(null);

	const [tenantDataList, setTenantDataList] = useState<any>(null);

	// This is to define the current url location of the user
	// This is mostly use to define
	const onLocationChange = (location: Location) => {
		console.log(location);
		setCurrentLocation(location.pathname);
	};

	// Quick simple check for sign-ins
	localStorage.removeItem("isLogin");
	if (!restricted.includes(currentLocation)) {
		if (
			(!localStorage.getItem("accessToken") &&
				!localStorage.getItem("idToken")) ||
			localStorage.getItem("accessToken") === "undefined" ||
			localStorage.getItem("idToken") === "undefined"
		) {
			localStorage.removeItem("accessToken");
			localStorage.removeItem("idToken");
			shouldRedirect = true;
		} else {
			isLogin = true;
		}
	}

	// Emitter controller - Control events within the app itself
	const emitterController = () => {
		Emitter.on("alert", (payload: any) => {
			if (payload) {
				if (payload.timeout) {
					if (timeout) {
						clearTimeout(timeout);
						timeout = null;
					}
					timeout = setTimeout(() => {
						setAlert(null);
					}, payload.timeout);
				}
				setAlert({
					type: payload.type,
					message: payload.message,
					description: payload.description,
					top: payload.top,
					closeable: payload.closable,
				});
			} else {
				setAlert(null);
			}
		});
	};

	// Socket Controller - control events with the back-end server and will conduct all socket io messages
	const socketController = () => {
		const socketServer: string = process.env.REACT_APP_SOCKET_SERVER as string;
		// const socketIDToken: any = localStorage.getItem(`${Tenant}:idToken`);

		socket.on("connect", () => {
			setInterval(() => {
				const start = Date.now();

				socket.emit("ping", () => {
					const duration = Date.now() - start;
					// console.log("Latency:", duration + "ms");
				});
			}, 1000);
		});
	};

	return (
		<ConfigProvider theme={{ hashed: false, algorithm: darkAlgorithm }}>
			<div className="main-page">
				<Router>
					<Location onChange={onLocationChange}></Location>
					{shouldRedirect ? <Navigate to="" /> : <></>}
					<Routes>
						<Route path="" element={<DigitalTwin />} />
						<Route path="*" element={<NoAccess />} />,
					</Routes>
				</Router>
				{alert && (
					<Alert
						className={
							alert?.top ? "alert-message-box-top" : "alert-message-box"
						}
						type={alert?.type}
						message={alert?.message}
						description={alert?.description}
						showIcon
						closable={alert?.closable || false}
						afterClose={() => setAlert(null)}
						style={{
							fontFamily: "Poppins, sans-serif",
							...getAlertStyle(alert?.type),
						}}
					/>
				)}
			</div>
		</ConfigProvider>
	);
};

export default App;
