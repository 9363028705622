import { io } from "socket.io-client";

const tenant = process.env.REACT_APP_CLIENT_TENANT_ID;
const socketServer: string = process.env.REACT_APP_SOCKET_SERVER as string;
const socketIDToken: any = localStorage.getItem(`${tenant}:idToken`);
let sessionDate: any = new Date().getTime();
sessionDate = (sessionDate - (sessionDate % 1000)) / 1000;
console.log(process.env.NODE_ENV);
const path =
	process.env.NODE_ENV == "development" ? "/socket.io" : `/${tenant}/socket.io`;

console.log(path);

export const socket = io(socketServer, {
	path: path,
	query: { token: socketIDToken, sessiondt: sessionDate },
	withCredentials: true,
});

console.log(socket);
