import axios from "axios";
import { AuthHeader, url } from "./_exports";
export const getAllVesselsKognifai = () => {
	return new Promise((resolve: any, reject: any) => {
		axios
			.get(`${url}/kognifai/getAllVessels`, AuthHeader)
			.then((data: any) => {
				resolve(data.data);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};

export const listVesselTags = (vessel: string) => {
	return new Promise((resolve: any, reject: any) => {
		axios
			.get(`${url}/kognifai/listVesselTags/${vessel}`, AuthHeader)
			.then((data: any) => {
				resolve(data);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};

export const getVesselTag = (
	vessel: string,
	tag: string,
	from: string,
	to: string,
	interval: string
) => {
	return new Promise((resolve: any, reject: any) => {
		//console.log(vessel, tag, from, to, interval);
		const _url = `${url}/kognifai/getVesselTag/${vessel}/${tag}`;
		axios
			.get(
				`${_url}?start_date=${from}&end_date=${to}&interval=${interval}`,
				AuthHeader
			)
			.then((data: any) => {
				resolve(data);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};
