import axios from "axios";
import { AuthHeader, url } from "./_exports";
export const getMaximoData = (
	assetNum: String,
	code: String,
	useTag: Boolean = false
) => {
	let params = {};

	if (useTag) {
		params = { "oslc.where": `assettag="${assetNum}" AND location=${code}` };
	} else {
		params = { "oslc.where": `assetnum="${assetNum}" AND location=${code}` };
	}

	return new Promise((resolve: any, reject: any) => {
		console.log(params);
		// resolve(null);
		axios
			.get(`${url}/maximo/getMaximoData`, { params: params, ...AuthHeader })
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};

export const getMaximoList = (assetNum: String) => {
	return new Promise((resolve: any, reject: any) => {
		axios
			.get(`${url}/models/hierarchy/maximo-list-${assetNum}.json`, {
				...AuthHeader,
			})
			.then((response: any) => {
				resolve(response.data);
			})
			.catch((err: any) => {
				reject(err);
			});
	});
};
