import axios from "axios";
import { AuthHeader, url, group_uid, idToken } from "./_exports";

export const getEngineeringAsset = (code: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/models/engineering`, {
				params: { code: code },
				...AuthHeader,
			})
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				console.error("error", e);
				reject(e);
			});
	});
};
