import React from "react";
import {
	AimOutlined,
	AlertOutlined,
	AreaChartOutlined,
	ArrowLeftOutlined,
	ArrowsAltOutlined,
	BankOutlined,
	BarChartOutlined,
	BarcodeOutlined,
	BoxPlotOutlined,
	BulbOutlined,
	ClockCircleOutlined,
	CloseOutlined,
	CloudDownloadOutlined,
	CloudOutlined,
	CloudSyncOutlined,
	CloudUploadOutlined,
	CoffeeOutlined,
	CommentOutlined,
	CompressOutlined,
	ContactsOutlined,
	DashOutlined,
	DashboardOutlined,
	DeleteOutlined,
	DislikeOutlined,
	DollarOutlined,
	DotChartOutlined,
	DownOutlined,
	EditOutlined,
	EllipsisOutlined,
	EnvironmentOutlined,
	EuroCircleOutlined,
	ExpandOutlined,
	ExperimentOutlined,
	FallOutlined,
	FieldTimeOutlined,
	FileImageOutlined,
	FileOutlined,
	FilePdfOutlined,
	FolderTwoTone,
	FrownOutlined,
	FundOutlined,
	GiftOutlined,
	GroupOutlined,
	HeartOutlined,
	HeatMapOutlined,
	HolderOutlined,
	InfoCircleOutlined,
	InfoOutlined,
	LeftOutlined,
	LikeOutlined,
	LineChartOutlined,
	LockOutlined,
	LogoutOutlined,
	MailOutlined,
	MehOutlined,
	MenuFoldOutlined,
	MenuOutlined,
	MenuUnfoldOutlined,
	MinusOutlined,
	NotificationOutlined,
	PlusOutlined,
	PieChartOutlined,
	PoundOutlined,
	PushpinOutlined,
	QuestionCircleOutlined,
	QuestionOutlined,
	RadarChartOutlined,
	RightOutlined,
	RiseOutlined,
	RobotOutlined,
	RocketOutlined,
	ScanOutlined,
	SearchOutlined,
	SettingOutlined,
	ShopOutlined,
	ShoppingCartOutlined,
	ShoppingOutlined,
	ShrinkOutlined,
	SlidersOutlined,
	SmileOutlined,
	StarOutlined,
	StockOutlined,
	SyncOutlined,
	TagOutlined,
	TeamOutlined,
	ToolOutlined,
	TrophyOutlined,
	UnlockOutlined,
	UserOutlined,
	CheckOutlined,
	SmallDashOutlined,
	UngroupOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";

export const ant_icons = [
	"rocket",
	"dashboard",
	"map",
	"piechart",
	"area",
	"bar",
	"dot",
	"line",
	"radar",
	"heat",
	"fall",
	"rise",
	"stock",
	"boxplot",
	"fund",
	"slider",
	"setting",
	"bulb",
	"team",
	"user",
	"infocircle",
	"info",
	"barcode",
	"menu",
	"robot",
	"pushpin",
	"heart",
	"star",
	"gift",
	"comment",
	"notification",
	"clock",
	"edit",
	"image",
	"file",
	"experiment",
	"search",
	"mail",
	"aim",
	"alert",
	"tool",
	"contacts",
	"question",
	"close",
	"delete",
	"menufold",
	"menuunfold",
];

export const GetAntIcon = (type: string) => {
	if (type === undefined || type === null) return <></>;
	switch ((type as string).toLowerCase()) {
		case "map":
			return <EnvironmentOutlined className="antd-icon" />;
		case "plus":
			return <PlusOutlined className="antd-icon" />;
		case "minus":
			return <MinusOutlined className="antd-icon" />;
		case "delete":
			return <DeleteOutlined className="antd-icon" />;
		case "pushpin":
			return <PushpinOutlined className="antd-icon" />;
		case "contacts":
		case "contact":
			return <ContactsOutlined className="antd-icon" />;
		case "refresh":
			return <SyncOutlined className="antd-icon" />;
		case "tool":
			return <ToolOutlined className="antd-icon" />;
		case "bank":
			return <BankOutlined className="antd-icon" />;
		case "search":
			return <SearchOutlined className="antd-icon" />;
		case "gift":
			return <GiftOutlined className="antd-icon" />;
		case "pound":
			return <PoundOutlined className="antd-icon" />;
		case "dollar":
			return <DollarOutlined className="antd-icon" />;
		case "euro":
			return <EuroCircleOutlined className="antd-icon" />;
		case "aim":
			return <AimOutlined className="antd-icon" />;
		case "alert":
			return <AlertOutlined className="antd-icon" />;
		case "like":
			return <LikeOutlined className="antd-icon" />;
		case "dislike":
			return <DislikeOutlined className="antd-icon" />;
		case "scan":
			return <ScanOutlined className="antd-icon" />;
		case "shop":
			return <ShopOutlined className="antd-icon" />;
		case "mail":
			return <MailOutlined className="antd-icon" />;
		case "lock":
			return <LockOutlined className="antd-icon" />;
		case "unlock":
			return <UnlockOutlined className="antd-icon" />;
		case "experiment":
			return <ExperimentOutlined className="antd-icon" />;
		case "trophy":
			return <TrophyOutlined className="antd-icon" />;
		case "happy":
			return <SmileOutlined className="antd-icon" />;
		case "meh":
			return <MehOutlined className="antd-icon" />;
		case "sad":
			return <FrownOutlined className="antd-icon" />;
		case "img":
		case "image":
			return <FileImageOutlined className="antd-icon" />;
		case "edit":
			return <EditOutlined className="antd-icon" />;
		case "cloud":
			return <CloudOutlined className="antd-icon" />;
		case "download":
			return <CloudDownloadOutlined className="antd-icon" />;
		case "upload":
			return <CloudUploadOutlined className="antd-icon" />;
		case "sync":
			return <CloudSyncOutlined className="antd-icon" />;
		case "comment":
			return <CommentOutlined className="antd-icon" />;
		case "clock":
			return <ClockCircleOutlined className="antd-icon" />;
		case "barcode":
			return <BarcodeOutlined className="antd-icon" />;
		case "cart":
			return <ShoppingCartOutlined className="antd-icon" />;
		case "star":
			return <StarOutlined className="antd-icon" />;
		case "shopping":
			return <ShoppingOutlined className="antd-icon" />;
		case "tag":
			return <TagOutlined className="antd-icon" />;
		case "robot":
			return <RobotOutlined className="antd-icon" />;
		case "leftarrow":
			return <LeftOutlined className="antd-icon" />;
		case "rightarrow":
			return <RightOutlined className="antd-icon" />;
		case "downarrow":
			return <DownOutlined className="antd-icon" />;
		case "piechart":
			return <PieChartOutlined className="antd-icon" />;
		case "setting":
		case "settings":
			return <SettingOutlined className="antd-icon" />;
		case "dashboard":
			return <DashboardOutlined className="antd-icon" />;
		case "dash":
			return <DashOutlined className="antd-icon" />;
		case "small-dash":
			return <SmallDashOutlined className="antd-icon" />;
		case "logout":
			return <LogoutOutlined className="antd-icon" />;
		case "bulb":
			return <BulbOutlined className="antd-icon" />;
		case "heart":
			return <HeartOutlined className="antd-icon" />;
		case "team":
			return <TeamOutlined className="antd-icon" />;
		case "user":
			return <UserOutlined className="antd-icon" />;
		case "file":
			return <FileOutlined className="antd-icon" />;
		case "rocket":
			return <RocketOutlined className="antd-icon" />;
		case "infocircle":
			return <InfoCircleOutlined className="antd-icon" />;
		case "info":
			return <InfoOutlined className="antd-icon" />;
		case "menu":
			return <MenuOutlined className="antd-icon" />;
		case "notification":
			return <NotificationOutlined className="antd-icon" />;
		case "coffee":
			return <CoffeeOutlined className="antd-icon" />;
		case "question":
			return <QuestionOutlined className="antd-icon" />;
		case "question3":
			return <QuestionCircleOutlined className="antd-icon" />;
		case "question2":
			return (
				<QuestionCircleOutlined
					style={{ color: "red" }}
					className="antd-icon"
				/>
			);
		case "folder":
			return (
				<FolderTwoTone
					style={{ fontSize: "20px" }}
					twoToneColor={"#F8D775"}
					className="antd-icon"
				/>
			);
		case "back":
			return (
				<ArrowLeftOutlined style={{ fontSize: "15px" }} className="antd-icon" />
			);
		case "close":
			return <CloseOutlined className="antd-icon" />;
		case "pdf":
			return <FilePdfOutlined className="antd-icon" />;
		case "arrowexpand":
			return <ArrowsAltOutlined className="antd-icon" />;
		case "expand":
			return <ExpandOutlined className="antd-icon" />;
		case "compress":
			return <CompressOutlined className="antd-icon" />;
		case "collapse":
			return <ShrinkOutlined className="antd-icon" />;
		case "comingsoon":
			return <FieldTimeOutlined className="antd-icon" />;
		case "ellipsis":
			return <EllipsisOutlined className="antd-icon" />;
		case "area":
			return <AreaChartOutlined className="antd-icon" />;
		case "bar":
			return <BarChartOutlined className="antd-icon" />;
		case "dot":
			return <DotChartOutlined className="antd-icon" />;
		case "line":
			return <LineChartOutlined className="antd-icon" />;
		case "radar":
			return <RadarChartOutlined className="antd-icon" />;
		case "heat":
			return <HeatMapOutlined className="antd-icon" />;
		case "fall":
			return <FallOutlined className="antd-icon" />;
		case "rise":
			return <RiseOutlined className="antd-icon" />;
		case "stock":
			return <StockOutlined className="antd-icon" />;
		case "boxplot":
			return <BoxPlotOutlined className="antd-icon" />;
		case "fund":
			return <FundOutlined className="antd-icon" />;
		case "slider":
			return <SlidersOutlined className="antd-icon" />;
		case "menufold":
			return <MenuFoldOutlined className="antd-icon" />;
		case "menuunfold":
			return <MenuUnfoldOutlined className="antd-icon" />;
		case "check":
			return <CheckOutlined />;
		case "group":
			return <GroupOutlined className="antd-icon" />;
		case "ungroup":
			return <UngroupOutlined className="antd-icon" />;
		case "exclamation":
			return <ExclamationCircleOutlined className="antd-icon" />;
		default:
			return <HolderOutlined className="antd-icon" />;
	}
};
