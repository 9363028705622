import Emitter from "../EventEmitter";

export const Tenant = process.env.REACT_APP_CLIENT_TENANT_ID;
export const Type = process.env.REACT_APP_TYPE;
export const url = process.env.REACT_APP_SERVER_URL as string;
export const group_uid = process.env.REACT_APP_CLIENT_TENANT_GROUP_UID;
export let accessToken: any = localStorage.getItem(`${Tenant}:accessToken`);
export const idToken: any = localStorage.getItem(`${Tenant}:idToken`);
export let pbiToken: any = localStorage.getItem(`${Tenant}:pbiToken`);
export let AuthHeader: any = {
	headers: {
		Authorization: accessToken,
	},
	withCredentials: true,
};

Emitter.on("refreshed", () => {
	accessToken = localStorage.getItem(`${Tenant}:accessToken`);
	pbiToken = localStorage.getItem(`${Tenant}:pbiToken`);
	AuthHeader = {
		headers: {
			Authorization: accessToken,
		},
		withCredentials: true,
	};
});
